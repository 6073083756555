import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, paddingBottomAndTop, RESET_CONTAINER_STYLE, setBackgroundColor } from '../../styles/globals';

export const useGridStyles = makeStyles<Theme>((theme) => ({
  root: ({ backgroundColor }: StylesProps) => ({
    ...setBackgroundColor(backgroundColor, theme)
  }),
  container: (props: StylesProps) => ({
    ...marginBottomAndTop(props.marginTop, props.marginBottom),
    ...paddingBottomAndTop(props.paddingBottom, props.paddingTop),
    [theme.breakpoints.between('xs', 'md')]: {
      ...marginBottomAndTop(props.tabletMarginTop, props.tabletMarginBottom),
      ...paddingBottomAndTop(props.tabletPaddingBottom, props.tabletPaddingBottom)
    },
    [theme.breakpoints.only('xs')]: {
      ...marginBottomAndTop(props.mobileMarginTop, props.mobileMarginBottom),
      ...paddingBottomAndTop(props.mobilePaddingBottom, props.mobilePaddingTop)
    }
  }),
  editableArea: (props: any) => ({
    display: 'flex',
    flexDirection: props.gridDirection,
    position: 'relative',
    paddingRight: 0,
    paddingLeft: 0,
    ...paddingBottomAndTop(props.paddingBottom, props.paddingTop),
    [theme.breakpoints.between('xs', 'md')]: {
      ...paddingBottomAndTop(props.tabletPaddingBottom, props.tabletPaddingTop)
    },
    [theme.breakpoints.only('xs')]: {
      ...paddingBottomAndTop(props.mobilePaddingBottom, props.mobilePaddingTop),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0px',
      borderRight: 'none',
      marginRight: '0px'
    }
  }),

  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
