import { makeStyles } from '@material-ui/core/styles';

export const useContactCardStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#6D5A7F',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#6D5A7F',
    padding: '30px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  text: {
    padding: 0,
    width: '100%',
    textAlign: 'left',
    marginLeft: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 30,
      marginLeft: 0,
      width: '100%',
      textAlign: 'center',
    }
  },
  image: {
    maxWidth: 700,
    borderTopRightRadius: 200,
    borderBottomRightRadius: 200,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      marginRight: 10,
    }
  },
  formMapContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    }
  },
  formContainer: {
    marginTop: 40,
    [theme.breakpoints.up('sm')]: {
      marginRight: 30,
    }
  },
  form: {
    marginBottom: 30,
    marginTop: 40,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  map: {
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    minHeight: 400,
    marginTop: 40,
  },
  footerContactFormImage: {
    height: 200,
  },
  officeList:{
    listStyle: 'none',
    padding: 0,
  },
  officeAddress: {
    marginBottom: 10,
  }
}));
