import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

export const useImageStyles = makeStyles((theme) => ({
  container: ({
    marginTop,
    marginBottom,
    tabletMarginBottom,
    tabletMarginTop,
    mobileMarginBottom,
    mobileMarginTop,
    justifyContent
  }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justifyContent,
    alignItems: 'center',
    flexWrap: 'wrap',
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative'
    }
  }),
  imageWrapper: {
    flexBasis: 'auto',
  },
  removeHeight: {
    height: 0,
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  appendTop: {
    transform: 'translateY(-50%)'
  }
}));
