import { makeStyles } from '@material-ui/core/styles';

const widthIntroContent = 50;

export const useNewsDetailStyles = makeStyles((theme) => ({
  toSubscribeContainer: {
    marginTop: 100
  },
  introContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    '& .container-main': {
      display: 'flex',
      flexDirection: 'column',
      width: `${widthIntroContent}%`,
      paddingTop: 67,
      paddingRight: 20,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '& .date-created': {
        background: '#F8B18E',
        width: 'max-content',
        fontWeight: 'bold',
        fontSize: 18,
        color: 'white',
        padding: '7px 20px',
        marginBottom: 30,
        [theme.breakpoints.down('xs')]: {
          fontSize: 9
        }
      },
      '& .title': {
        fontWeight: 'bold',
        fontSize: 50,
        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
          marginTop: 5
        }
      },
      '& .subtitle': {
        fontSize: 35,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14
        }
      },
      '& .container-author': {
        fontSize: 16,
        color: '#999999',
        letterSpacing: 3,
        marginTop: 18,
        [theme.breakpoints.down('xs')]: {
          fontSize: 12
        },
        '& .author': {
          color: 'initial'
        }
      }
    },
    '& .container-image': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: `${100 - widthIntroContent}%`,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '& img': {
        width: '100%'
      }
    }
  },
  contentContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  titleNews: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '140px 0 120px 0',
    letterSpacing: '4.58px',
    fontSize: 23,
    [theme.breakpoints.down('xs')]: {
      margin: '50px 0 50px 0'
    }
  },
  titleNewsSpan: {
    letterSpacing: '4.58px',
    fontSize: 37,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  sectionTitle: {
    cursor: 'default',
    color: '#f4f4f4',
    fontWeight: 'bold',
    letterSpacing: 15,
    fontSize: '10rem',
    textTransform: 'uppercase',
    marginBottom: '-36px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
}));
