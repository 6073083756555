import { Container, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAPIPage, getCurrentPathname } from '../../helpers/AppHelpers';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { News } from '../../models/News';
import { StylesProps } from '../../models/StylesProps';
import { get } from '../../services/http.service';
import { getCountPagination, getPaginatedCollection } from '../../utils/PaginationUtils';
import { SlideProps } from '../slide/Slide';
import { NewsItemList } from './components/NewsItemList';
import { useNewsListStyles } from './NewsList.styles';

interface Props extends SlideProps, StylesProps {
  metadata: MagnoliaMetadata;
}

const MAX_PAGINATION = 10;

export const NewsList: React.VFC<Props> = (props) => {
  const styles = useNewsListStyles();
  const [news, setNews] = React.useState<News[]>([]);
  const history = useHistory();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    loadNews();
  }, []);

  React.useEffect(() => window.scrollTo(0,0), [page]);
  
  const loadNews = async () => {
    try {
      const result = await get<News[]>(getAPIPage(getCurrentPathname(history.location.pathname), true), { baseURL: '' });
      setNews(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (_: React.ChangeEvent<unknown>, page: number): void => setPage(page);

  const paginatedNews = React.useMemo(() => getPaginatedCollection(news, page, MAX_PAGINATION), [news, page]);

  return (
    <Container>
      <Typography variant="h2" className={styles.title}>Noticias</Typography>
      {paginatedNews.map((data, index) => (
        <NewsItemList key={data['@id']} news={data} bottomDivider={index + 1 !== paginatedNews.length} />
      ))}

      {news.length && (
        <Pagination count={getCountPagination(news.length, MAX_PAGINATION)} onChange={handleChange} className={styles.pagination} />
      )}
    </Container>
  );
};

NewsList.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'false'
};
