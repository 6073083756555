import { makeStyles } from '@material-ui/core/styles';

const useNewsDetailStyles = makeStyles((theme) => ({
  date: {
    background: '#F8B18E',
    width: 'max-content',
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    padding: '7px 20px',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 9
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 100,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    }
  },
  image: {
    position: 'absolute',
  },
  textContainer: {
    padding: 30,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 100,
    marginBottom: 70,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 10,
    }
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20
    },
    '& img': {
      width: '100%'
    }
  },
  titleNews: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '140px 0 120px 0',
    letterSpacing: '4.58px',
    fontSize: 23,
    [theme.breakpoints.down('xs')]: {
      margin: '50px 0 50px 0'
    }
  },
  titleNewsSpan: {
    letterSpacing: '4.58px',
    fontSize: 37,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  buttonBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 18,
  }
}));

export default useNewsDetailStyles;
