import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

export const useCustomerOpinionsStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(to right, #f17680 0%, #f17680 52%, #C06C86 52%, #C06C86 100%)',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      background: 'linear-gradient(to bottom, #f17680 0%, #f17680 52%, #C06C86 52%, #C06C86 100%)'
    }
  },
  video: {
    background: '#f17680',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      padding: 33,
      background: '#C06C86',
      justifyContent: 'center'
    }
  },

  constainerDescriptions: {
    color: '#fff',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    padding: 37,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 30
    }
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 414,
    height: 414,
    borderRadius: '50%',
    '& .image-button': {
      color: 'white',
      width: 100,
      height: 100
    },
    [theme.breakpoints.down('xs')]: {
      width: 244,
      height: 244
    }
  },
  constainerInformations: {
    width: '40%',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 56,
    '& div': {
      width: 313
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: 0,
      margin: 30,
      minHeight: '232px !important',
      '& div': {
        width: '100%'
      }
    }
  },
  butonPlay: {
    width: 100,
    height: 100,
    border: '3px solid #fff',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  }
}));

export const useCustomerOpinionsContainerStyles = makeStyles((theme) => ({
  root: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    width: '100% ',
    display: 'flex',
    ...marginBottomAndTop(marginTop, marginBottom),
    background: 'linear-gradient(to right, #f17680 0%, #f17680 54%, #C06C86 54%, #c06b86 100%)',
    [theme.breakpoints.down('xs')]: {
      background: ' #f17680 ',
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom),
      [theme.breakpoints.only('md')]: {
        ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
      }
    }
  })
}));
