import { Box, Container } from '@material-ui/core';
import React from 'react';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import useFooterStyles from './Footer.style';

export interface FooterProps {
  logo: MagnoliaImage;
  contact: string;
  visit: string;
  copyright: string;
  multiLegal: any;
  multiSeal: any;
  multiSocial: any;
}

const Footer: React.VFC<FooterProps> = (props) => {
  const classes = useFooterStyles();

  const socialIconsLinks =
    props.multiSocial &&
    props.multiSocial['@nodes'].map((node, index) => {
      const itemNode = props.multiSocial[node];
      return (
        <a href={itemNode.page} key={index}>
          <Image image={itemNode.image} />
        </a>
      );
    });

  const sealIconsLinks =
    props.multiSeal &&
    props.multiSeal['@nodes'].map((node, index) => {
      const itemNode = props.multiSeal[node];
      return <Image key={index} image={itemNode.image} />;
    });

  const legalLinks = props.multiLegal['@nodes']?.map((node, index) => {
    const itemNode = props.multiLegal[node];

    return (
      <div key={index} className="legal-item">
        <div className="dot" />
        <a href={itemNode.page}>
          <li>{itemNode.text}</li>
        </a>
      </div>
    );
  });

  return (
    <Box>
      <Container>
        <div className={classes.root}>
          <div className="first-row">
            <div className="logo-column">
              <Image image={props.logo} />
            </div>
            <div className="text-column">
              <div className="contact">
                <RichText text={props.contact ?? ''} />
              </div>
              <div className="visit">
                <RichText text={props.visit ?? ''} />
              </div>
            </div>
            <div className="social-column">
              <div className="icons-list">{props.multiSocial && socialIconsLinks}</div>
              <div className="seals-images">{props.multiSeal && sealIconsLinks}</div>
            </div>
          </div>
          <hr />

          <div className="second-row">
            <div className="copyright">
              <p>{props.copyright}</p>
            </div>
            {!!legalLinks?.length && (
              <div className="link-area">
                <nav>
                  <ul>{legalLinks}</ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
