import { Box, CssBaseline } from '@material-ui/core';
import createTheme from '@material-ui/core/styles/createTheme';
import { ThemeProvider } from '@material-ui/styles';
import { defaultsDeep } from 'lodash';
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loading from './components/loading/Loading';
import { getRouterBasename } from './helpers/AppHelpers';
import PageLoader from './helpers/PageLoader';
import { DialogsContextProvider } from './providers/DialogProvider';
import { SnackbarProvider } from './providers/SnackbarProvider';
import ScrollToTop from './scrollToTop';
import i18n from './services/locale/locale.service';
import { baseThemeConfig } from './styles/themes/baseTheme.config';
import { defaultThemeConfig } from './styles/themes/defaultTheme.config';

const App = () => {
  const [loadingLocale, setLoadingLocale] = useState(true);

  i18n.on('initialized', () => setLoadingLocale(false));

  return (
    <BrowserRouter basename={getRouterBasename()}>
      <ThemeProvider theme={createTheme(defaultsDeep({}, baseThemeConfig, defaultThemeConfig))}>
        <SnackbarProvider>
          <DialogsContextProvider>
            <CssBaseline />
            {loadingLocale ? (
              <Loading />
            ) : (
              <Box>
                <ScrollToTop />
                <Switch>
                  <Route path="/" component={PageLoader} />
                </Switch>
              </Box>
            )}
          </DialogsContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
