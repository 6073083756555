import { makeStyles } from '@material-ui/core/styles';

export const useControlledFileInputStyles = makeStyles({
  fileInput: {
    '&::-webkit-file-upload-button': {
      display: 'none'
    }
  },
  fileButton: {
    padding: '0px 27px',
    marginBottom: 7
  }
});
