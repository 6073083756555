export enum Endpoints {
  JOB = '/jobs-applications',
  CONTACT = '/contact',
  DOWNLOAD = '/download'
};

export enum MagnoliaEndpoints {
  HOME = '/',
  JOB_OFFERS = '/unete2',
  NEWS = '/noticias',
  PROJECTS = '/casos-de-exito',
  TECHNOLOGY = '/como-lo-hacemos'
};