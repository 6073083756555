import { makeStyles } from '@material-ui/core/styles';
import { marginBottomAndTop, RESET_CONTAINER_STYLE } from '../../styles/globals';
import { CardLabelProps } from './CardLabel';

export const useCardLabelStyles = makeStyles((theme) => ({
  root: ({
    cardDirection,
    backgroundColor,
    marginTop,
    marginBottom,
    tabletMarginBottom,
    tabletMarginTop,
    mobileMarginBottom,
    mobileMarginTop
  }: CardLabelProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    },
    background: `linear-gradient(${cardDirection === 'toLeft' ? 'to left' : 'to right'}, ${backgroundColor} 48%, #fff 48%)`,
  }),
  content: ({ cardDirection }: CardLabelProps) => ({
    display: 'flex',
    justifyContent: cardDirection === 'toLeft' ? 'flex-end' : 'flex-start'
  }),
  cardBody: ({ cardDirection, cardSize, backgroundColor }: CardLabelProps) => ({
    '&[card-size="480"][card-length="normal"]': {
      width: '78%'
    },
    '&[card-size="265"][card-length="normal"]': {
      width: '67%'
    },
    '&[card-size="480"][card-length="long"]': {
      width: '100%'
    },
    '&[card-size="265"][card-length="long"]': {
      width: '84%'
    },
    backgroundColor: `${backgroundColor}`,
    display: 'flex',
    color: '#fff',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: cardDirection === 'toLeft' ? `${cardSize}px 0 0 ${cardSize}px` : `0 ${cardSize}px ${cardSize}px 0`,
    [theme.breakpoints.down('xs')]: {
      '&[card-size="480"]': {
        width: '100% !important',
        borderRadius: cardDirection === 'toLeft' ? '$125px 0 0 125px' : '0 125px 125px 0'
      },
      '&[card-size="265"]': {
        width: '100% !important',
        borderRadius: cardDirection === 'toLeft' ? '$150px 0 0 150px' : '0 150px 150px 0'
      }
    }
  }),
  cardRichtext: {
    '&[card-size="480"][card-length="normal"]': {
      width: '53%'
    },
    '&[card-size="265"][card-length="normal"]': {
      width: '65%'
    },
    '&[card-size="480"][card-length="long"]': {
      width: '63%'
    },
    '&[card-size="265"][card-length="long"]': {
      width: '74%'
    },
    [theme.breakpoints.down('xs')]: {
      '& span': {
        fontSize: '18px !important'
      }
    }
  },
  cardImage: ({ cardSize }: CardLabelProps) => ({
    borderRadius: '50%',
    width: `${cardSize}px`,
    height: `${cardSize}px`,
    [theme.breakpoints.down('xs')]: {
      width: cardSize === '480' ? 150 : 125,
      height: cardSize === '480' ? 150 : 125
    },
    '& img': {
      width: `${cardSize}px`,
      height: `${cardSize}px`,
      padding: cardSize === '480' ? 30 : 17,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: cardSize === '480' ? 150 : 125,
        height: cardSize === '480' ? 150 : 125,
        padding: cardSize === '480' ? 10 : 9
      }
    }
  }),
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
