import { ArrowForward, GetApp, LocationOnOutlined, SvgIconComponent } from '@material-ui/icons';
import { IconName } from '../../styles/types/IconName';

export const getIconComponent = (name: IconName): SvgIconComponent => {
  switch (name) {
    case 'download':
      return GetApp;
    case 'arrow-forward':
      return ArrowForward;
    case 'location':
      return LocationOnOutlined;
    default:
      return null;
  }
};
