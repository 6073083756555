import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { JobOffer } from '../../models/JobOffer';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { StylesProps } from '../../models/StylesProps';
import { useSwipperNavigationStyles } from '../../styles/common/swiper-navigation';
import { ModalRef } from '../modal/modal';
import { filterOffers } from './JobOffersGrid.business';
import { usePresentationContentStyles } from './JobOffersGrid.styles';

interface Props extends StylesProps {
  header?: MagnoliaJcrMultiField;
  footer?: MagnoliaJcrMultiField;
  metadata?: MagnoliaMetadata;
}

export const JobOffersGrid: React.VFC<Props> = (props) => {
  const MAX_PAGE_ITEMS = 9;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const swipperStyles = useSwipperNavigationStyles(false);
  const [jobOffers, setJobOffers] = React.useState<JobOffer[]>([]);
  const styles = usePresentationContentStyles({ ...props });
  const parentTemplateId = props.metadata ? props.metadata['mgnl:template'] : '';
  const modalRef = React.useRef<ModalRef>();
  const [filters, setFilters] = React.useState<Record<string, string[]>>();

  React.useEffect(() => {
    // get<JobOffer[]>(getAPIPage(MagnoliaEndpoints.JOB_OFFERS, true), { baseURL: '' }).then(({ data }) => setJobOffers(data));
  }, []);
  if (!document.getElementById('teamtailorscriptinnocv')) {
    const script = document.createElement('script');
    script.src = 'https://scripts.teamtailor-cdn.com/widgets/production/jobs.js';
    script.async = true;
    script.charset = 'utf-8';
    script.id = 'teamtailorscriptinnocv';
    document.body.appendChild(script);
  }
  const toggleModal = () => modalRef.current?.toggleModal();
  const onChangeFilters = (newFilters: Record<string, string[]>) => setFilters(newFilters);
  const filteredOffers = React.useMemo(() => filterOffers(jobOffers, filters), [jobOffers, filters]);

  const showPagination = isDesktop ? jobOffers.length > 9 : jobOffers.length > 3;

  return (
    <div className={styles.background}>
      <Container>
        <div
          className={clsx(styles.teamtailor, 'teamtailor-jobs-widget')}
          data-teamtailor-limit="9"
          data-teamtailor-pagination="true"
          data-teamtailor-department-select="true"
          data-teamtailor-role-select="true"
          data-teamtailor-location-select="true"
          data-teamtailor-api-key="wjy2u4-Yffc13jiw-UHw7IPf-U-mFTUN-eFOkAHx"
        ></div>
      </Container>
      {/* <Filter modalRef={modalRef} columns={filterColumns} onChangeFilters={onChangeFilters} title="OFERTAS" />
      <Container>
        <div className={styles.filterButtonContainer}>
          <Button className={styles.filterButton} onClick={toggleModal}>
            Filtrar ofertas
          </Button>
        </div>
        <Box>{props.header && <EditableArea content={props.header} parentTemplateId={parentTemplateId} />}</Box>
        {!!jobOffers.length && (
          <>
            <Swiper
              navigation={{
                prevEl: '#navigation-prev',
                nextEl: '#navigation-next',
                disabledClass: swipperStyles.disabledNavigation
              }}
              pagination={{
                clickable: true,
                el: '#pagination',
                bulletClass: swipperStyles.bullet,
                bulletActiveClass: swipperStyles.activeBullet
              }}
              slidesPerView={isDesktop ? 3 : 1}
              slidesPerColumnFill={'row'}
              slidesPerColumn={3}
              slidesPerGroup={3}
              role="grid"
              direction="horizontal"
              loopFillGroupWithBlank={true}
            >
              {filteredOffers.map((value, index) => (
                <SwiperSlide key={index} className={styles.slide}>
                  <JobOfferItem
                    rightDivider={value ? showRightDivider(isDesktop, index + 1, MAX_PAGE_ITEMS) : null}
                    bottomDivider={value ? showBottomDivider(isDesktop, index + 1, MAX_PAGE_ITEMS) : false}
                    title={value ? value.title : ''}
                    subtitle={value ? value.subtitle : ''}
                    description={value ? value.shortDescription : ''}
                    path={value ? value['@name'] : null}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {showPagination && (
              <div className={styles.navigationContainer}>
                <div id="navigation-prev" className={swipperStyles.navigation}>
                  &#9664;
                </div>
                <div id="pagination" />
                <div id="navigation-next" className={swipperStyles.navigation}>
                  &#9654;
                </div>
              </div>
            )}
          </>
        )} 
        <Box>{props.footer && <EditableArea content={props.footer} parentTemplateId={parentTemplateId} />}</Box>
      </Container>*/}
    </div>
  );
};
