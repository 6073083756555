import { makeStyles } from '@material-ui/core/styles';

export const useFilterStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  columnContainer: {
    display: 'flex',
    marginTop: 40,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    }
  },
  offersLabel: {
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  divider: {
    marginTop: 10,
  }
}));
