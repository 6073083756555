import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

const useInnovationsStyles = makeStyles((theme) => ({
  container: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),

    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
  root: {
    display: 'flex',
    paddingTop: 50,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    '& .constainer-descriptions': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '40%',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 'x-small'
      },
      '& .rich-text': {
        width: '100%'
      }
    },
    '& .constainer-buttons': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: 195,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& .constainer-image': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: 20,
        maxHeight: 300
      },
      '& img': {
        width: '100%'
      }
    }
  }
}));

export default useInnovationsStyles;
