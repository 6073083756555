import { Box } from '@material-ui/core';
import React from 'react';
import { Innovation } from '../../../models/Innovation';
import { MagnoliaJcrMultiField } from '../../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../../models/StylesProps';
import { ButtonList, ButtonListProps } from '../../button_list/ButtonList';
import { Image } from '../../image/Image';
import RichText from '../../rich_text/RichText';
import { default as useInnovationsPruebaStyles } from '../Innovations.styles';

interface GenerateInnovationsProps extends StylesProps {
  slides: MagnoliaJcrMultiField;
}

export const GenerateInnovations = ({ slides, marginBottom, marginTop }: GenerateInnovationsProps) => {
  const slideNodes: string[] = slides['@nodes'];
  const classes = useInnovationsPruebaStyles({ marginBottom, marginTop });

  return slideNodes.map((name: string) => {
    const innovation: Innovation = slides[name];
    const buttons: MagnoliaJcrMultiField = innovation.buttons;
    const buttonListProps: ButtonListProps = {
      button: buttons,
      buttonPosition: 'left',
      flexWrap: 'wrap',
      marginBottom: '0',
      marginTop: '54'
    };

    return (
      <Box className={classes.root} key={name}>
        <div className="constainer-descriptions">
          <RichText text={innovation.descriptions ?? ''} />
          <Box className="constainer-buttons">{buttons['@nodes'].length !== 0 && <ButtonList {...buttonListProps} />}</Box>
        </div>
        <div className="constainer-image">
          <Image image={innovation.image} />
        </div>
      </Box>
    );
  });
};
