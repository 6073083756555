import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import RichText from '../rich_text/RichText';
import { useRichTextListStyles } from './RichtTextList.style';

interface RichTextListProps extends StylesProps {
  multiRichText: any;
  id?: string;
  hasDivider?: string;
}

export const RichTextList: React.VFC<RichTextListProps> = (props) => {
  const classes = useRichTextListStyles(props);

  const texts =
    props.multiRichText &&
    props.multiRichText['@nodes'].map((node, index) => {
      const itemNode = props.multiRichText[node];

      return (
        <>
          <div className="richtext-item">
            <RichText text={itemNode.text ?? ''} />
          </div>

          {JSON.parse(props.hasDivider) && index !== props.multiRichText['@nodes'].length - 1 && <div className="divider" />}
        </>
      );
    });

  return (
    <Box className={classes.root}>
      <Container id={props.id} className={clsx({ [classes.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
        <div className={classes.richtextRow}>{texts}</div>
      </Container>
    </Box>
  );
};

RichTextList.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'true'
};
