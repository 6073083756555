import React from 'react';
import { getMagnoliaImage } from '../../../helpers/AppHelpers';

interface GenerateImagesCarouselProps {
  slides: Object;
  slideNodes: string[];
}

const GenerateImagesCarousel = (props: GenerateImagesCarouselProps) => {
  return props.slideNodes.map((name) => {
    const { image } = props.slides[name];
    return <img key={name} src={getMagnoliaImage(image['@id'] ?? image)} alt={image['@name'] ?? ''} />;
  });
};

export default GenerateImagesCarousel;
