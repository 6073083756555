import { makeStyles, Theme } from '@material-ui/core/styles';

const useSlideStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& .swiper-container': {
      '& .swiper-wrapper': {
        display: 'flex',
        alignItems: 'center',
        '& .swiper-slide': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: 0,
          '& i': {
            maxWidth: '100%'
          }
        },
        '& img': {
          maxWidth: '100%',
          [theme.breakpoints.down('xs')]: {
            maxWidth: '80%'
          }
        }
      },
      '& .swiper-pagination': {
        bottom: 0
      }
    },
    '& .swiper-pagination-bullet': {
      border: '2px solid #5b5b5b',
      backgroundColor: 'white',
      width: 10,
      height: 10,
      [theme.breakpoints.down('xs')]: {
        width: 8,
        height: 8
      }
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: '#5b5b5b'
    }
  },
  arrowIcon: {
    opacity: 0.5,
    fontSize: 60,
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px !important'
    }
  }
}));

export default useSlideStyles;
