import { makeStyles } from '@material-ui/core/styles';

export const usePresentationContentStyles = makeStyles((theme) => ({
  title: {
    fontSize: 137,
    height: 145,
    fontWeight: 'bold',
    color: '#f4f4f4'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: 'x-small'
    }
  },
  root: {
    display: 'flex',
    paddingTop: 50,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 'fit-content'
    }
  },
  curriculumForm: {
    position: 'absolute'
  },
  sendCVButton: {
    [theme.breakpoints.between('sm', 'lg')]: {
      marginBottom: 30,
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20
    },
    '& img': {
      width: '100%'
    }
  }
}));
