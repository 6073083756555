import { Box, Divider } from '@material-ui/core';
import React from 'react';
import { CardStylesProps } from '../../models/CardStylesProps';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { ButtonList } from '../button_list/ButtonList';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import { useCardItemStyles } from './CardItem.style';

export interface CardItemProps extends StylesProps, CardStylesProps {
  rightDivider?: boolean;
  hasDivider?: boolean;
  image: MagnoliaImage;
  buttons: MagnoliaJcrMultiField;
  text: string;
  page: string;
  informations: any;
  date: string;
  link: string;
  icon: MagnoliaImage;
}

export const CardItem: React.VFC<CardItemProps> = (props) => {
  const classes = useCardItemStyles({ ...props });

  const buttons: MagnoliaJcrMultiField = props.buttons;
  const date: Date = props.date === undefined ? null : new Date(props.date);

  return (
    <div className={classes.column}>
      <Box className={classes.root}>
        <div className="container-image">
          {props.image && <Image image={props.image} />}
          {props.icon && (
            <div className="icon">
              <Image image={props.icon} />
            </div>
          )}
        </div>
        <div className="container-informations">
          {date !== null && (
            <div className="container-date">
              {new Intl.DateTimeFormat('es-ES', {
                year: 'numeric',
                month: 'short'
              })
                .format(date)
                .toUpperCase()}
            </div>
          )}

          {props.link !== undefined && (
            <a className="link" href={props.page}>
              <div>{props.link}</div>
            </a>
          )}
          <div className="container-descriptions">
            <RichText text={props.informations ?? ''} />
          </div>
          <Box className="container-buttons">
            {buttons['@nodes'].length !== 0 && (
              <ButtonList button={buttons} buttonPosition="center" flexWrap="wrap" marginBottom="0" marginTop="0" />
            )}
          </Box>
        </div>
      </Box>
      {props.hasDivider && props.rightDivider && <Divider className={classes.rightDivider} />}
    </div>
  );
};

CardItem.defaultProps = {
  height: '100',
  marginInformationTop: '-366',
  marginInformationLeft: '54',
  marginInformationRight: '54',
  marginLeftOfDate: '274',
  topOfDate: '23',
  marginLeftOfLink: '328',
  topOfLink: '23',
  topOfIcon: '74',
  marginLeftOfIcon: '300',
  mobileHeightInformation: '100',
  mobileMarginInformationLeft: '17',
  mobileMarginInformationRight: '37',
  mobileMarginInformationTop: '-268',
  mobileLeftOfDate: '226',
  backgroundCardColor: '#fff',
  boxShadow: '5px 5px 20px #00000019 !important'
};
