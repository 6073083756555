import { makeStyles } from '@material-ui/core/styles';

export const useLatestNewsItemOddStyles = makeStyles((theme) => ({
  container: (isEven) =>  ({
    display: 'flex',
    flexDirection: isEven ? 'column-reverse' : 'column',
    alignItems: 'center',
    textDecoration: 'none',
    minWidth: '33%',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '16%',
      marginTop: isEven ? '16%': 0,
    },
  }),
  image: (isEven) => ({
    width: '100%',
    height: 225,
    borderRadius: isEven ? '0 0 225px 225px' : '225px 225px 0 0',
    [theme.breakpoints.down('xs')]: {
      height: 170
    },
  }),
  authorContainer: {
    fontSize: 10,
    color: '#999999'
  },
  author: {
    color: '#4d4d4d'
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  link: (isEven) => ({
    fontSize: 38,
    background: '#f8b18e',
    width: 45,
    height: 91,
    borderRadius: '0 100px 100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 20,
    right: -45,
    color: '#fff',
    fontWeight: 'bold',
    top: isEven ? 20 : null,
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 70,
      right: -35,
      fontSize: 35
    }
  }),
  contentContainer: (isEven) => ({
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'relative',
    background: '#fff',
    width: '80%',
    height: 'auto',
    padding: 38,
    color: 'black',
    marginTop: !isEven ? -70 : 0,
    marginBottom: isEven ? -70 : 0
  }),
  descriptionContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer'
  },
  newLabel: {
    marginLeft: 2,
    backgroundColor: '#315c7f',
    textTransform: 'uppercase',
    color: '#fff',
    textAlign: 'center',
    padding: '5px 15px',
    fontWeight: 'bold',
  },
  dateLabel: {
    marginLeft: 2,
    textTransform: 'uppercase',
    color: '#fff',
    textAlign: 'center',
    padding: '5px 15px',
    fontWeight: 'bold',
    backgroundColor: '#f8b18e',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
