import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { first } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { initReactI18next } from 'react-i18next';
import { DateLocales, localeOpts } from './locale.config';
import languageDetector from './locale.detector';
import { Languages } from './locale.model';

moment.updateLocale(Languages.ES, DateLocales[Languages.ES]);

i18n.on('languageChanged', (lang) => {
  const locale = first(lang.split('-'));
  moment.locale(locale);
});

i18n.use(Backend).use(languageDetector).use(initReactI18next).init(localeOpts);

export default i18n;
