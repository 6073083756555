import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, RESET_CONTAINER_STYLE } from '../../styles/globals';

export const useRichTextListStyles = makeStyles((theme) => ({
  root: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
  richtextRow: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    '& .richtext-item': {
      width: '100%',
      maxWidth: 1440,
      color: 'rgba(0, 0, 0, 0.87)',
      '& span': {
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px !important'
        }
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          width: '35% !important',
          height: '35% !important'
        }
      }
    },
    '& .divider': {
      borderLeft: '1px solid #EEEEEE'
    },
    '& .richtext-item + .divider': {
      marginLeft: 30,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    '& .divider + .richtext-item': {
      marginLeft: 50,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    '& .richtext-item + .richtext-item': {
      marginLeft: 100,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    }
  },
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
