import { makeStyles } from '@material-ui/core/styles';

export const useImageCardStyles = makeStyles((theme) => ({
  container: ({ reverse }: { reverse: boolean }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    background: 'white',
    padding: 30,
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.up('md')]: {
      flexDirection: reverse ? 'row-reverse' : 'row'
    }
  }),
  textContainer: ({ reverse }: { reverse: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '55%',
      marginRight: reverse ? 0 : 30,
      marginLeft: reverse ? 30 : 0,
    }
  }),
  image: {
    width: '100%'
  },
  swiper: {
    width: '100%'
  },
  swiperContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-70px)',
      width: '45%'
    }
  },
  bullet: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    borderRadius: '50%',
    borderColor: theme.palette.common.black,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:not(:last-of-type)': {
      marginRight: 10
    }
  },
  activeBullet: {
    background: theme.palette.common.black
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  buttonList: {
    marginTop: 30,
  },
  icon: {
    marginTop: 10,
  }
}));
