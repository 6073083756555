import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import RubikLightItalicWoff2 from '../../assets/fonts/rubik/Rubik-LightItalic.woff2';
import RubikLightItalicWoff from '../../assets/fonts/rubik/Rubik-LightItalic.woff';
import RubikLightItalicTtf from '../../assets/fonts/rubik/Rubik-LightItalic.ttf';
import RubikItalicWoff2 from '../../assets/fonts/rubik/Rubik-Italic.woff2';
import RubikItalicWoff from '../../assets/fonts/rubik/Rubik-Italic.woff';
import RubikItalicTtf from '../../assets/fonts/rubik/Rubik-Italic.ttf';
import RubikMediumItalicWoff2 from '../../assets/fonts/rubik/Rubik-MediumItalic.woff2';
import RubikMediumItalicWoff from '../../assets/fonts/rubik/Rubik-MediumItalic.woff';
import RubikMediumItalicTtf from '../../assets/fonts/rubik/Rubik-MediumItalic.ttf';
import RubikSemiBoldItalicWoff2 from '../../assets/fonts/rubik/Rubik-SemiBoldItalic.woff2';
import RubikSemiBoldItalicWoff from '../../assets/fonts/rubik/Rubik-SemiBoldItalic.woff';
import RubikSemiBoldItalicTtf from '../../assets/fonts/rubik/Rubik-SemiBoldItalic.ttf';
import RubikBoldItalicWoff2 from '../../assets/fonts/rubik/Rubik-BoldItalic.woff2';
import RubikBoldItalicWoff from '../../assets/fonts/rubik/Rubik-BoldItalic.woff';
import RubikBoldItalicTtf from '../../assets/fonts/rubik/Rubik-BoldItalic.ttf';
import RubikExtraBoldItalicWoff2 from '../../assets/fonts/rubik/Rubik-ExtraBoldItalic.woff2';
import RubikExtraBoldItalicWoff from '../../assets/fonts/rubik/Rubik-ExtraBoldItalic.woff';
import RubikExtraBoldItalicTtf from '../../assets/fonts/rubik/Rubik-ExtraBoldItalic.ttf';
import RubikBlackItalicWoff2 from '../../assets/fonts/rubik/Rubik-BlackItalic.woff2';
import RubikBlackItalicWoff from '../../assets/fonts/rubik/Rubik-BlackItalic.woff';
import RubikBlackItalicTtf from '../../assets/fonts/rubik/Rubik-BlackItalic.ttf';
import RubikLightWoff2 from '../../assets/fonts/rubik/Rubik-Light.woff2';
import RubikLightWoff from '../../assets/fonts/rubik/Rubik-Light.woff';
import RubikLightTtf from '../../assets/fonts/rubik/Rubik-Light.ttf';
import RubikMediumWoff2 from '../../assets/fonts/rubik/Rubik-Medium.woff2';
import RubikMediumWoff from '../../assets/fonts/rubik/Rubik-Medium.woff';
import RubikMediumTtf from '../../assets/fonts/rubik/Rubik-Medium.ttf';
import RubikRegularWoff2 from '../../assets/fonts/rubik/Rubik-Regular.woff2';
import RubikRegularWoff from '../../assets/fonts/rubik/Rubik-Regular.woff';
import RubikRegularTtf from '../../assets/fonts/rubik/Rubik-Regular.ttf';
import RubikSemiBoldWoff2 from '../../assets/fonts/rubik/Rubik-SemiBold.woff2';
import RubikSemiBoldWoff from '../../assets/fonts/rubik/Rubik-SemiBold.woff';
import RubikSemiBoldTtf from '../../assets/fonts/rubik/Rubik-SemiBold.ttf';
import RubikBoldWoff2 from '../../assets/fonts/rubik/Rubik-Bold.woff2';
import RubikBoldWoff from '../../assets/fonts/rubik/Rubik-Bold.woff';
import RubikBoldTtf from '../../assets/fonts/rubik/Rubik-Bold.ttf';
import RubikExtraBoldWoff2 from '../../assets/fonts/rubik/Rubik-ExtraBold.woff2';
import RubikExtraBoldWoff from '../../assets/fonts/rubik/Rubik-ExtraBold.woff';
import RubikExtraBoldTtf from '../../assets/fonts/rubik/Rubik-ExtraBold.ttf';
import RubikBlackWoff2 from '../../assets/fonts/rubik/Rubik-Black.woff2';
import RubikBlackWoff from '../../assets/fonts/rubik/Rubik-Black.woff';
import RubikBlackTtf from '../../assets/fonts/rubik/Rubik-Black.ttf';

const RubikLightItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 300,
  fontDisplay: 'swap',
  src: `
  url(${RubikLightItalicWoff2}) format('woff2'),
  url(${RubikLightItalicWoff}) format('woff'),
  url(${RubikLightItalicTtf}) format('truetype')
  `
};

const RubikItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 400,
  fontDisplay: 'swap',
  src: `
  url(${RubikItalicWoff2}) format('woff2'),
  url(${RubikItalicWoff}) format('woff'),
  url(${RubikItalicTtf}) format('truetype')
  `
};

const RubikMediumItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 500,
  fontDisplay: 'swap',
  src: `
  url(${RubikMediumItalicWoff2}) format('woff2'),
  url(${RubikMediumItalicWoff}) format('woff'),
  url(${RubikMediumItalicTtf}) format('truetype')
  `
};

const RubikSemiBoldItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 600,
  fontDisplay: 'swap',
  src: `
  url(${RubikSemiBoldItalicWoff2}) format('woff2'),
  url(${RubikSemiBoldItalicWoff}) format('woff'),
  url(${RubikSemiBoldItalicTtf}) format('truetype')
  `
};

const RubikBoldItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 700,
  fontDisplay: 'swap',
  src: `
  url(${RubikBoldItalicWoff2}) format('woff2'),
  url(${RubikBoldItalicWoff}) format('woff'),
  url(${RubikBoldItalicTtf}) format('truetype')
  `
};

const RubikExtraBoldItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 800,
  fontDisplay: 'swap',
  src: `
  url(${RubikExtraBoldItalicWoff2}) format('woff2'),
  url(${RubikExtraBoldItalicWoff}) format('woff'),
  url(${RubikExtraBoldItalicTtf}) format('truetype')
  `
};
const RubikBlackItalic: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'italic',
  fontWeight: 900,
  fontDisplay: 'swap',
  src: `
  url(${RubikBlackItalicWoff2}) format('woff2'),
  url(${RubikBlackItalicWoff}) format('woff'),
  url(${RubikBlackItalicTtf}) format('truetype')
  `
};

const RubikLight: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 300,
  fontDisplay: 'swap',
  src: `
  url(${RubikLightWoff2}) format('woff2'),
  url(${RubikLightWoff}) format('woff'),
  url(${RubikLightTtf}) format('truetype')
  `
};

const RubikRegular: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: 'swap',
  src: `
  url(${RubikRegularWoff2}) format('woff2'),
  url(${RubikRegularWoff}) format('woff'),
  url(${RubikRegularTtf}) format('truetype')
  `
};

const RubikMedium: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 500,
  fontDisplay: 'swap',
  src: `
  url(${RubikMediumWoff2}) format('woff2'),
  url(${RubikMediumWoff}) format('woff'),
  url(${RubikMediumTtf}) format('truetype')
  `
};

const RubikSemiBold: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontDisplay: 'swap',
  src: `
  url(${RubikSemiBoldWoff2}) format('woff2'),
  url(${RubikSemiBoldWoff}) format('woff'),
  url(${RubikSemiBoldTtf}) format('truetype')
  `
};

const RubikBold: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 700,
  fontDisplay: 'swap',
  src: `
  url(${RubikBoldWoff2}) format('woff2'),
  url(${RubikBoldWoff}) format('woff'),
  url(${RubikBoldTtf}) format('truetype')
  `
};

const RubikExtraBold: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 800,
  fontDisplay: 'swap',
  src: `
  url(${RubikExtraBoldWoff2}) format('woff2'),
  url(${RubikExtraBoldWoff}) format('woff'),
  url(${RubikExtraBoldTtf}) format('truetype')
  `
};

const RubikBlack: BaseCSSProperties['@font-face'] = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 900,
  fontDisplay: 'swap',
  src: `
  url(${RubikBlackWoff2}) format('woff2'),
  url(${RubikBlackWoff}) format('woff'),
  url(${RubikBlackTtf}) format('truetype'),
  `
};

export const RubikFont = [
  RubikLightItalic,
  RubikItalic,
  RubikMediumItalic,
  RubikSemiBoldItalic,
  RubikBoldItalic,
  RubikExtraBoldItalic,
  RubikBlackItalic,
  RubikLight,
  RubikRegular,
  RubikMedium,
  RubikSemiBold,
  RubikBold,
  RubikExtraBold,
  RubikBlack,
]