import { EditableArea } from '@magnolia/react-editor';
import { Box } from '@material-ui/core';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { TemplateSection } from '../templateSection/TemplateSection';

interface SectionProps extends StylesProps {
  sectionTitle: string;
  metadata: object;
  mainSection: object;
  titlePosition: string;
}

const Section: React.VFC<SectionProps> = (props) => {
  const parentTemplateId = props.metadata['mgnl:template'];

  return (
    <TemplateSection {...props}>
      {props.mainSection && <Box>{<EditableArea content={props.mainSection} parentTemplateId={parentTemplateId} />}</Box>}
    </TemplateSection>
  );
};

Section.defaultProps = {
  backgroundColor: 'none'
};

export default Section;
