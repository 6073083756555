import { omit } from 'lodash';
import { getDamImage } from '../../helpers/AppHelpers';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import {
  contactFormDefaultValues,
  ContactFormModel,
  curriculumFormDefaultValues,
  CurriculumFormModel,
  downloadFormDefaultValues,
  DownloadFormModel,
  FormTemplate
} from './form.constants';
import { sendContact, sendCV, sendDownloadInfo } from './form.service';
import { ContactForm } from './templates/ContactForm';
import { CurriculumForm } from './templates/CurriculumForm';
import { DownloadForm } from './templates/DownloadForm';

export const getFormTemplate = (template: FormTemplate) => {
  switch (template) {
    case FormTemplate.CV:
      return { component: CurriculumForm, defaultValues: curriculumFormDefaultValues };
    case FormTemplate.Contact:
      return { component: ContactForm, defaultValues: contactFormDefaultValues };
    case FormTemplate.Download:
      return { component: DownloadForm, defaultValues: downloadFormDefaultValues };
    default:
      return { component: CurriculumForm, defaultValues: curriculumFormDefaultValues };
  }
};

export const handleTemplateSubmit = async (template: FormTemplate, data: unknown, ...optionalArgs): Promise<void> => {
  switch (template) {
    case FormTemplate.CV:
      return await sendCV(data as CurriculumFormModel);
    case FormTemplate.Contact:
      const contact: ContactFormModel = omit(data as ContactFormModel, 'gdprAccept');
      return await sendContact(contact);
    case FormTemplate.Download:
      const downloadInfo = data as DownloadFormModel;
      const file: MagnoliaImage = optionalArgs[0]?.file;
      await sendDownloadInfo({...downloadInfo, fileName: file['@name'] });
      downloadFile(file)
      return await Promise.resolve();
    default:
      return await Promise.resolve();
  }
};

const downloadFile = (file: MagnoliaImage): void => {
  const element = document.createElement('a');
  element.setAttribute('href', getDamImage(file['@id']));
  document.body.appendChild(element);
  element.click()
  document.body.removeChild(element);
}