import i18n from 'i18next';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { FooterProps } from '../components/footer/Footer';
import { HeaderProps } from '../components/header/Header';
import { MagnoliaEndpoints } from '../constants';
import { getAPIPage } from '../helpers/AppHelpers';
import { get as httpGet } from '../services/http.service';

interface CommonNodes {
  footer: FooterProps;
  header: HeaderProps;
}

const langMap: Record<string, CommonNodes> = {};

export const useCommonNodes = () => {
  const [nodes, setNodes] = useState<CommonNodes>();
  useEffect(() => {
    const lang = i18n.language;
    const mapNodes = langMap[lang];
    if (mapNodes) {
      setNodes(mapNodes);
    } else {
      const path = getAPIPage(MagnoliaEndpoints.HOME);
      const config = { baseURL: '', params: { lang } };
      httpGet<CommonNodes>(path, config).then(({ data }) => {
        const headerNode = get(data, 'header.@nodes[0]', null);
        const footerNode = get(data, 'footer.@nodes[0]', null);
        const header = get(data, `header.${headerNode}`, null);
        const footer = get(data, `footer.${footerNode}`, null);
        const value = { header, footer };
        setNodes(value);
        langMap[lang] = value;
      });
    }
  }, []);
  return nodes;
};
