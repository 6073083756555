import { ActivitiesHeader } from './components/activities_header/ActivitiesHeader';
import { BoxSection } from './components/box_section/BoxSection';
import { ButtonList } from './components/button_list/ButtonList';
import { CardLabel } from './components/CardLabel/CardLabel';
import { CardItem } from './components/cards/CardItem';
import { CardList } from './components/card_list/CardList';
import { ContactCard } from './components/contactCard/ContactCard';
import { CustomerOpinions } from './components/customer_opinions/CustomerOpinions';
import { FeaturedProjects } from './components/featured_projects/FeaturedProjects';
import Footer from './components/footer/Footer';
import { GridItem } from './components/grid/components/GridItem';
import { Grid } from './components/grid/Grid';
import Header from './components/header/Header';
import Icon from './components/icon/Icon';
import { ImageDetail } from './components/image-detail/ImageDetail';
import { Image } from './components/image/Image';
import { ImageCard } from './components/ImageCard/ImageCard';
import { ImagesCarousel } from './components/images_carousel/ImagesCarousel';
import { ImageDescriptionList } from './components/imageWithDescriptionList/ImageDescriptionList';
import { Innovations } from './components/innovations/Innovations';
import { JobOffersGrid } from './components/jobOffersGrid/JobOffersGrid';
import { LatestNews } from './components/latest_news/LatestNews';
import PartnerBanner from './components/partner_banner/PartnerBanner';
import { PresentationContent } from './components/presentationContent/PresentationContent';
import RichText from './components/rich_text/RichText';
import { RichTextList } from './components/rich_text_list/RichTextList';
import Section from './components/section/Section';
import { SelectableContent } from './components/SelectableContent/SelectableContent';
import { VideoCard } from './components/VideoCard/VideoCard';
import { CustomPage } from './pages/CustomPage/CustomPage';
import { JobOffer } from './pages/jobOffer/JobOffer';
import { Technology } from './pages/technology/Technology';
import { Project } from './pages/Project/Project';
import NewsDetail from './pages/newsDetail/NewsDetail';
import { Page } from './pages/page/Page';
import { VideosCarousel } from './components/videos_carousel/VideosCarousel';
import { NewsList } from './components/newsList/NewsList';
import { ProjectCarousel } from './components/projectCarousel/ProjectCarousel';
import { TechnologyCarousel } from './components/technologyCarousel/technologyCarousel';

export const config = {
  componentMappings: {
    // Page templates
    'innocv-web-lm:pages/CustomPage': CustomPage,
    'innocv-web-lm:pages/Page': Page,

    // Posts templates
    'innocv-web-lm:pages/NewsDetail': NewsDetail,
    'innocv-web-lm:pages/JobOffer': JobOffer,
    'innocv-web-lm:pages/Technology': Technology,
    'innocv-web-lm:pages/Project': Project,

    // Components
    'innocv-web-lm:components/ContactCard': ContactCard,
    'innocv-web-lm:components/RichText': RichText,
    'innocv-web-lm:components/Image': Image,
    'innocv-web-lm:components/Header': Header,
    'innocv-web-lm:components/Section': Section,
    'innocv-web-lm:components/BoxSection': BoxSection,
    'innocv-web-lm:components/ButtonList': ButtonList,
    'innocv-web-lm:components/FeaturedProjects': FeaturedProjects,
    'innocv-web-lm:components/ImageDetail': ImageDetail,
    'innocv-web-lm:components/ImagesCarousel': ImagesCarousel,
    'innocv-web-lm:components/Innovations': Innovations,
    'innocv-web-lm:components/PartnerBanner': PartnerBanner,
    'innocv-web-lm:components/Footer': Footer,
    'innocv-web-lm:components/LatestNews': LatestNews,
    'innocv-web-lm:components/CustomerOpinions': CustomerOpinions,
    'innocv-web-lm:components/NewsDetail': NewsDetail,
    'innocv-web-lm:components/JobOffer': JobOffer,
    'innocv-web-lm:components/JobOffersGrid': JobOffersGrid,
    'innocv-web-lm:components/ActivitiesHeader': ActivitiesHeader,
    'innocv-web-lm:components/PresentationContent': PresentationContent,
    'innocv-web-lm:components/CardLabel': CardLabel,
    'innocv-web-lm:components/RichTextList': RichTextList,
    'innocv-web-lm:components/Card': CardItem,
    'innocv-web-lm:components/CardList': CardList,
    'innocv-web-lm:components/Grid': Grid,
    'innocv-web-lm:components/GridItem': GridItem,
    'innocv-web-lm:components/VideoCard': VideoCard,
    'innocv-web-lm:components/ImageCard': ImageCard,
    'innocv-web-lm:components/Icon': Icon,
    'innocv-web-lm:components/ImageDescriptionList': ImageDescriptionList,
    'innocv-web-lm:components/SelectableContent': SelectableContent,
    'innocv-web-lm:components/VideosCarousel': VideosCarousel,
    'innocv-web-lm:components/NewsList': NewsList,
    'innocv-web-lm:components/ProjectCarousel': ProjectCarousel,
    'innocv-web-lm:components/TechnologyCarousel': TechnologyCarousel,
  }
};
