import { EditableArea } from '@magnolia/react-editor';
import { AppBar } from '@material-ui/core';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { LatestNews } from '../../components/latest_news/LatestNews';

interface Props extends StylesProps {
  main?: object;
  header?: object;
  footer?: object;
}

export const CustomPage: React.VFC<Props> = ({ main, header, footer }) => (
  <>
    {header && (
      <AppBar position="sticky">
        <EditableArea content={header} />
      </AppBar>
    )}

    {main && <EditableArea content={main} />}
    <LatestNews />
    {footer && <EditableArea content={footer} />}
  </>
);
