import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, setBackgroundColor } from '../../styles/globals';

export const useSectionStyles = makeStyles<Theme>((theme) => ({
  container: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.between('xs', 'md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    },
    [theme.breakpoints.only('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    }
  }),
  sectionTitle: () => ({
    color: '#f4f4f4',
    fontWeight: 'bold',
    fontSize: 150,
    textTransform: 'uppercase',
    lineHeight: '115px',
    display: 'flex',
    marginBottom: -7,
    '&[data-align="center"]': {
      justifyContent: 'center'
    },
    '&[data-align="right"]': {
      justifyContent: 'flex-end',
      textAlign: 'right'
    },
    '&[data-align="left"]': {
      justifyContent: 'flex-start'
    },
    '&[flex-wrap="initial"]': {
      flexWrap: 'initial'
    },
    '&[flex-wrap="wrap"]': {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }),

  root: ({ backgroundColor }: StylesProps) => ({
    position: 'relative',
    color: '#fff!important',
    [theme.breakpoints.down('xs')]: {
      marginTop: 50
    },
    ...setBackgroundColor(backgroundColor, theme)
  })
}));
