import { makeStyles } from '@material-ui/core/styles';

export const useFilterColumnStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 10,
    }
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 25,
    paddingLeft: 25,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControlActive: {
    color: 'white'
  },
  formControlRoot:{
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    WebkitTapHighlightColor: 'transparent',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  filterLabel: {
    position: 'absolute',
    userSelect: 'none',
    marginRight: 0,
  },
  root: {
    width: 260,
    height: 50,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  track: {
    width: '100%',
    height: 30,
    borderRadius: 15,
    backgroundColor: '#F17680'
  },
  switchBase: {
    transform: 'translateX(-9px)',
    '&$checked': {
      color: 'white',
      transform: 'translateX(223px)',
      '& + $track': {
        backgroundColor: '#F17680',
        opacity: 1,
      }
    },
    '& + $track': {
      backgroundColor: '#DDDDDD',
      opacity: 1,
    }
  },
  checked: {},
  thumb: {
    marginTop: 1,
    width: 29,
    height: 29,
  },
  divider: {
    height: '100%',
    width: 1,
  }
}));
