import { EditableArea } from '@magnolia/react-editor';
import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { useGridStyles } from './Grid.style';

interface GridProps extends StylesProps {
  gridDirection: string;
  metadata: object;
  mainGrid: object;
}

export const Grid: React.VFC<GridProps> = (props) => {
  const styles = useGridStyles({ ...props });
  const parentTemplateId = props.metadata['mgnl:template'];

  return (
    <div className={styles.root}>
      <Container className={clsx(styles.container, { [styles.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
        <Box>
          {props.mainGrid && (
            <EditableArea className={clsx(styles.editableArea)} content={props.mainGrid} parentTemplateId={parentTemplateId} />
          )}
        </Box>
      </Container>
    </div>
  );
};

Grid.defaultProps = {
  backgroundColor: 'none',
  containerCheckbox: 'false',
  marginTop: '0',
  marginBottom: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
