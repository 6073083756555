import { Box, Button, Container, Modal, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImage } from '../../helpers/AppHelpers';
import { StylesProps } from '../../models/StylesProps';
import { useVideosCarouselStyles } from './VideosCarousel.style';

interface Video {
  id: string;
  title: string;
}

interface Props extends StylesProps {
  videos: {
    '@nodes': string[];
  };
}

export const VideosCarousel: React.VFC<Props> = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const videoNames = props.videos['@nodes'];
  const videos: Video[] = videoNames.map((it) => props.videos[it]);
  const styles = useVideosCarouselStyles({ ...props });
  const [videoUrl, setVideoUrl] = useState<string>();

  const showYoutubeVideo = (id: string) => () => setVideoUrl(`https://www.youtube.com/watch?v=${id}`);
  const onCloseModal = () => setVideoUrl(null);

  return (
    <Container className={styles.root}>
      <Box className={styles.swiperContainer}>
        <div id="navigation-prev" className={styles.navigation}>
          &#9664;
        </div>
        <Swiper
          className={styles.swiper}
          navigation={{
            prevEl: '#navigation-prev',
            nextEl: '#navigation-next',
            disabledClass: styles.disabledNavigation
          }}
          autoplay={false}
          slidesPerView={isDesktop ? 4 : 1}
          slidesPerGroup={isDesktop ? 4 : 1}
          slidesPerColumn={1}
          direction="horizontal"
        >
          {videos.map((it) => (
            <SwiperSlide key={it.id} className={styles.slide}>
              <Button className={styles.playButton} onClick={showYoutubeVideo(it.id)}>
                <img className={styles.image} src={`https://img.youtube.com/vi/${it.id}/maxresdefault.jpg`} />
                <PlayArrowRoundedIcon className={styles.playIcon} />
              </Button>
              <Box className={styles.titleContainer}>
                <img className={styles.logo} src={getImage('innocv-circle-logo.svg')} />
                <Typography className={styles.title}>{it.title}</Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <div id="navigation-next" className={styles.navigation}>
          &#9654;
        </div>
      </Box>

      <Modal open={!!videoUrl} className={styles.modal} onClose={onCloseModal}>
        <ReactPlayer className={styles.videoPlayer} width={isDesktop ? undefined : '90%'} playing={true} controls url={videoUrl} />
      </Modal>
    </Container>
  );
};

VideosCarousel.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'false'
};
