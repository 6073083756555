import { EditableArea } from '@magnolia/react-editor';
import React from 'react';
import { Layout } from '../../layout/Layout';

interface Props {
  main?: object;
  newsContent?: object;
}

export const Page: React.VFC<Props> = ({ main, newsContent }) => (
  <Layout content={newsContent}>{main && <EditableArea content={main} />}</Layout>
);
