import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, RESET_CONTAINER_STYLE } from '../../styles/globals';

const useRichTextStyles = makeStyles<Theme, StylesProps>((theme) => ({
  richText: ({
    marginTop,
    marginBottom,
    tabletMarginBottom,
    tabletMarginTop,
    mobileMarginBottom,
    mobileMarginTop,
    marginLeft,
    marginRight,
    mobileMarginLeft,
    mobileMarginRight
  }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('sm')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    },
    marginLeft: `${marginLeft}px !important`,
    marginRight: `${marginRight}px !important`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: `${mobileMarginLeft}px !important`,
      marginRight: `${mobileMarginRight}px !important`
    },
    '& img': {
      objectFit: 'contain',
      maxWidth: '100%'
    }
  }),
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  },
}));

export default useRichTextStyles;
