import { makeStyles } from '@material-ui/core/styles';

export const useProjectStyles = makeStyles((theme) => ({
  contactBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 100
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contactButton: {
    marginTop: 30,
    color: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    padding: '5px 20px'
  },
  contactTitle: {
    fontWeight: 400,
  },
  contactSubtitle: {
    fontWeight: 'bold',
    letterSpacing: 5,
  }
}));
