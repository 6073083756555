import { EditableArea } from '@magnolia/react-editor';
import { Box, Container, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { Image } from '../../components/image/Image';
import { Routes } from '../../constants';
import { Layout } from '../../layout/Layout';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { useNewsDetailStyles } from './NewsDetail.styles';

interface NewsDetailProps {
  main?: object;
  content?: object;
  header?: object;
  footer?: object;
  title: string;
  subtitle: string;
  author: string;
  abstract: string;
  image?: MagnoliaImage;
  metadata?: MagnoliaMetadata;
  date?: string;
}

const NewsDetail: React.VFC<NewsDetailProps> = (props) => {
  const styles = useNewsDetailStyles();
  const date = moment(props.date).format('DD-MM-yyyy');
  return (
    <Layout>
      <Box>
        <Container>
          <Box className={styles.introContainer}>
            <Box className={'container-main'}>
            {props.date && <Box className={'date-created'}>{date}</Box>}
              <Box className={'title'}>{props.title}</Box>
              <Box className={'subtitle'}>{props.subtitle}</Box>
              <Box className={'container-author'}>
                Por: <span className={'author'}>{props.author}</span>
              </Box>
              {props.main && <EditableArea content={props.main} />}
            </Box>
            <Box className={'container-image'}>
              <Image image={props.image} />
            </Box>
          </Box>
          <Box className={styles.contentContainer}>{props.content && <EditableArea content={props.content} />}</Box>
        </Container>
        <Typography variant="h1" className={styles.sectionTitle}>NOTICIAS</Typography>
      </Box>
    </Layout>
  );
};

export default NewsDetail;
