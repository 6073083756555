import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.common.white,
    minWidth: 450,
    maxWidth: 600,
    padding: 35,
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
    }
  },
  container: {
    maxWidth: 500,
  },
  title: {
    fontSize: 35,
    fontWeight: 200
  },
  subtitle: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  input: {
    marginBottom: 10
  },
  textArea: {
    marginBottom: 10,
    '&::placeholder': {
      fontStyle: 'italic',
    }
  },
  submitButton: {
    maxWidth: 320,
  },
}));
