import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, UseControllerProps, FieldError } from 'react-hook-form';

type TextFieldProps =
  | Omit<StandardTextFieldProps, 'onChange' | 'value'>
  | Omit<FilledTextFieldProps, 'onChange' | 'value'>
  | Omit<OutlinedTextFieldProps, 'onChange' | 'value'>;

interface Props<T> {
  controlProps: UseControllerProps<T>;
  textFieldProps: TextFieldProps;
  error?: FieldError;
}

export const ControlledTextField = <T,>(props: Props<T>) => (
  <Controller
    {...props.controlProps}
    render={({ field: { onChange, value } }) => (
      <TextField {...props.textFieldProps} onChange={onChange} value={value} error={!!props.error} helperText={props.error?.message} />
    )}
  />
);
