import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSlideStyles from './Slide.style';

SwiperCore.use([Autoplay, Navigation, Pagination, Grid]);

export interface SlideProps {
  desktopSlides: string;
  tabletSlides: string;
  mobileSlides: string;
  spaceBetween: string;
  navigation: string;
  pagination: string;
  loop?: string;
  autoplay?: string;
  delay?: string;
  desktopRows: string;
  tabletRows?: string;
  mobileRows?: string;
  nodes: Object[];
  marginBottom?: string;
  marginTop?: string;
}

const Slide: React.VFC<SlideProps> = (props) => {
  const classes = useSlideStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [slides, setSlides] = useState(0);
  const [rows, setRows] = useState(1);
  const id = uniqueId();

  useEffect(() => {
    if (isMobile) {
      setSlides(Number(props.mobileSlides));
      setRows(Number(props.mobileRows));
    }
  }, [isMobile]);

  useEffect(() => {
    if (isTablet) {
      setSlides(Number(props.tabletSlides));
      setRows(Number(props.tabletRows));
    }
  }, [isTablet]);

  useEffect(() => {
    if (isDesktop) {
      setSlides(Number(props.desktopSlides));
      setRows(Number(props.desktopRows));
    }
  }, [isDesktop]);

  return (
    <Box className={classes.root}>
      {slides > 0 && (
        <>
          {JSON.parse(props.navigation) && (
            <>
              <Box id={`prev-${id}`}>
                <ArrowLeftIcon className={classes.arrowIcon} />
              </Box>
            </>
          )}

          <Swiper
            navigation={
              JSON.parse(props.navigation) && {
                prevEl: `#prev-${id}`,
                nextEl: `#next-${id}`
              }
            }
            pagination={JSON.parse(props.pagination) ? { clickable: true } : false}
            autoplay={JSON.parse(props.autoplay) ? { delay: Number(props.delay) } : false}
            loop={JSON.parse(props.loop)}
            slidesPerGroup={slides}
            slidesPerView={slides}
            spaceBetween={+props.spaceBetween}
            slidesPerColumn={+rows}
            slidesPerColumnFill="row"
          >
            {props.nodes.map((value, index) => (
              <SwiperSlide key={index}>{value}</SwiperSlide>
            ))}
          </Swiper>

          {JSON.parse(props.navigation) && (
            <>
              <Box id={`next-${id}`}>
                <ArrowRightIcon className={classes.arrowIcon} />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

Slide.defaultProps = {
  desktopSlides: '1',
  mobileSlides: '1',
  tabletSlides: '1',
  pagination: 'false',
  navigation: 'false',
  loop: 'false',
  autoplay: 'false',
  delay: '0',
  spaceBetween: '0',
  desktopRows: '1',
  tabletRows: '1',
  mobileRows: '1'
};

export default Slide;
