import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

const itemHeaderLogoSize = {
  width: 328,
  height: 126
};
const itemMainImageSize = 414;
const itemMainImageSizeBorder = 30;
const itemMainImageSizeMobile = 250;

export const useFeaturedProjectsStyles = makeStyles<Theme, StylesProps>((theme) => ({
  mainContainer: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
  itemContainer: {
    backgroundColor: '#C06C86'
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 20
    }
  },
  itemHeaderSector: {
    marginRight: 45,
    lineHeight: 1.25,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginRight: 0,
      paddingBottom: 20
    }
  },
  itemHeaderSectorLabel: {
    fontSize: 25
  },
  itemHeaderSectorValue: {
    fontSize: 34,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  itemHeaderLogo: {
    position: 'relative',
    width: itemHeaderLogoSize.width,
    height: itemHeaderLogoSize.height,
    minWidth: itemHeaderLogoSize.width,
    minHeight: itemHeaderLogoSize.height,
    maxWidth: itemHeaderLogoSize.width,
    maxHeight: itemHeaderLogoSize.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    '& img': {
      maxWidth: itemHeaderLogoSize.width - 45,
      maxHeight: itemHeaderLogoSize.height - 45
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%'
    }
  },
  itemMain: {
    backgroundImage: 'linear-gradient(to right, transparent 50% , #F17680 50%)',
    height: itemMainImageSize,
    maxHeight: itemMainImageSize,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
      maxHeight: 'initial',
      backgroundImage: 'linear-gradient(transparent 50%, transparent 50%)'
    }
  },
  itemMainWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: itemMainImageSize,
    maxHeight: itemMainImageSize,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: 'initial',
      maxHeight: 'initial'
    }
  },
  itemMainImageWrapper: {
    width: itemMainImageSize,
    height: itemMainImageSize,
    minWidth: itemMainImageSize,
    minHeight: itemMainImageSize,
    maxWidth: itemMainImageSize,
    maxHeight: itemMainImageSize,
    backgroundImage: 'linear-gradient(to right, transparent 50% , #F17680 50%)',
    [theme.breakpoints.down('xs')]: {
      width: itemMainImageSizeMobile,
      height: itemMainImageSizeMobile,
      minWidth: itemMainImageSizeMobile,
      minHeight: itemMainImageSizeMobile,
      maxWidth: itemMainImageSizeMobile,
      maxHeight: itemMainImageSizeMobile,
      backgroundImage: 'linear-gradient(transparent 50%, transparent 50%)',
      position: 'relative',
      zIndex: 2
    }
  },
  itemMainImage: {
    width: itemMainImageSize,
    height: itemMainImageSize,
    minWidth: itemMainImageSize,
    minHeight: itemMainImageSize,
    maxWidth: itemMainImageSize,
    maxHeight: itemMainImageSize,
    borderRadius: itemMainImageSize,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    border: `solid ${itemMainImageSizeBorder}px #F17680`,
    [theme.breakpoints.down('xs')]: {
      width: itemMainImageSizeMobile,
      height: itemMainImageSizeMobile,
      minWidth: itemMainImageSizeMobile,
      minHeight: itemMainImageSizeMobile,
      maxWidth: itemMainImageSizeMobile,
      maxHeight: itemMainImageSizeMobile,
      borderRadius: itemMainImageSizeMobile
    }
  },
  itemMainContent: {
    backgroundColor: '#F17680',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    boxSizing: 'border-box',
    padding: '30px 0px',
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: 'flex-start',
      position: 'relative',
      zIndex: 1
    }
  },
  itemMainTextContent: {
    maxHeight: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'initial',
      margin: '0px -16px',
      padding: '0px 16px',
      paddingTop: 110,
      marginTop: -125,
      paddingBottom: 30,
      backgroundColor: '#F17680'
    }
  },
  itemMainButtonContent: {
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 32px)',
      margin: '0px -16px',
      padding: '0px 16px',
      backgroundColor: '#F17680',
      paddingBottom: 30,
      '& > a': {
        marginLeft: 0
      }
    }
  }
}));
