import { Box, Button, Modal as MaterialModal } from '@material-ui/core';
import React from 'react';
import { useModalStyles } from './modal.styles';

export interface ModalRef {
  toggleModal: () => void;
  isOpen: boolean;
}

interface Props {
  children: React.ReactNode;
  showCloseButton?: boolean;
}

export const Modal = React.forwardRef<ModalRef, Props>(({ children, showCloseButton }, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const styles = useModalStyles();

  const toggleModal = React.useCallback(() => setOpen(!open), [open]);

  React.useImperativeHandle(ref, () => ({ isOpen: open, toggleModal }), [open]);

  return (
    <MaterialModal open={open} onClose={toggleModal} className={styles.container}>
      <div className={styles.centerContainer}>
        <Box className={styles.childrenContainer}>
          {showCloseButton && (
            <Button variant="contained" onClick={toggleModal} className={styles.closeButton}>
              X
            </Button>
          )}
          {children}
        </Box>
      </div>
    </MaterialModal>
  );
});

Modal.displayName = 'Modal';
Modal.defaultProps = {
  showCloseButton: true
};
