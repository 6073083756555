import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, RESET_CONTAINER_STYLE } from '../../styles/globals';

export const useBoxSectionStyles = makeStyles<Theme>((theme) => ({
  root: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
  row: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },
  column: {
    position: 'relative',
    padding: '10px 0px',
    paddingRight: '50px',
    marginRight: '85px',
    borderRight: 'solid 1px #fff',
    '&:last-child': {
      paddingRight: '0px',
      borderRight: 'none',
      marginRight: '0px',
      '& .divider': {
        borderBottom: 'none'
      }
    },
    '& .divider': {
      [theme.breakpoints.down('xs')]: {
        width: 129,
        height: 21,
        borderBottom: 'solid 1px #999999'
      }
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0px',
      borderRight: 'none',
      marginRight: '0px'
    }
  },
  divider: { background: '#fff', marginTop: 20 },
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
