import { Container } from '@material-ui/core';
import React from 'react';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { ButtonComponent } from '../button/ButtonComponent';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import { useFeaturedProjectsStyles } from './FeaturedProjects.style';

export interface FeaturedProjectsProps extends StylesProps {
  project: MagnoliaJcrMultiField;
}

interface FeaturedProjectsModel {
  sector: string;
  logo: MagnoliaImage;
  image: MagnoliaImage;
  description: string;
  link: string;
}

export const FeaturedProjects: React.FC<FeaturedProjectsProps> = (props) => {
  const classes = useFeaturedProjectsStyles({ ...props });
  console.log('FeaturedProjects', props);
  
  return (
    <div className={classes.mainContainer}>
      {props.project['@nodes'].map((node) => {
        const projectModel: FeaturedProjectsModel = props.project[node];
        console.log(projectModel)
        return (
          <div key={node} className={classes.itemContainer}>
            <div className={classes.itemHeader}>
              <div className={classes.itemHeaderSector}>
                {projectModel.sector && <div dangerouslySetInnerHTML={{ __html: projectModel.sector }} />}
              </div>
              <div className={classes.itemHeaderLogo}>
                <Image image={projectModel.logo} />
              </div>
            </div>
            <div className={classes.itemMain}>
              <Container className={classes.itemMainWrapper}>
                <div className={classes.itemMainImageWrapper}>
                  <div
                    className={classes.itemMainImage}
                    style={projectModel.image && { backgroundImage: `url(${getMagnoliaImage(projectModel.image['@id'])})` }}
                  ></div>
                </div>
                <div className={classes.itemMainContent}>
                  <div className={classes.itemMainTextContent}>
                    <RichText text={projectModel.description} />
                  </div>
                  {projectModel.link && (
                    <div className={classes.itemMainButtonContent}>
                      <ButtonComponent isColorWhite={true} variant="outlined" href={projectModel.link}>
                        Ver el proyecto
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </div>
        );
      })}
    </div>
  );
};

FeaturedProjects.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
