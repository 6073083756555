import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { MagnoliaImage, MagnoliaImageList } from '../../models/magnolia/MagnoliaImage';
import { StylesProps } from '../../models/StylesProps';
import { arrayByObjectKey } from '../../utils/ObjectUtils';
import { useImageStyles } from './Image.styles';
import { LazyImage } from './LazyImage';

interface ImageProps extends StylesProps {
  image: MagnoliaImage;
  images?: MagnoliaImageList[];
  className?: string;
  wrapperClassName?: string;
  removeHeight?: string;
  appendTop?: string;
}

export const Image: React.VFC<ImageProps> = (props) => {
  if(!props.image && !props.images) {
    return <React.Fragment/>
  }
  const styles = useImageStyles({ ...props });
  const images = props.images?.['@nodes'].length && arrayByObjectKey<MagnoliaImageList>(props.images, 'images');
  return (
    <Box className={clsx(styles.container, { [styles.removeHeight]: JSON.parse(props.removeHeight) })}>
      {images?.length ? (
        images?.map(({ image }, index) => (
          <LazyImage
            wrapperClassName={clsx(styles.imageWrapper, { [styles.appendTop]: JSON.parse(props.appendTop) })}
            image={image}
            key={index}
          />
        ))
      ) : (
        <LazyImage
          className={props.className}
          wrapperClassName={clsx(props.wrapperClassName, { [styles.removeHeight]: JSON.parse(props.removeHeight) })}
          image={props.image}
        />
      )}
    </Box>
  );
};

Image.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'false',
  removeHeight: 'false',
  justifyContent: 'flex-start',
  appendTop: 'false'
};
