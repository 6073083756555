import { makeStyles } from '@material-ui/core';
import { StylesProps } from '../../models/StylesProps';

export const useSelectableContentStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  removePadding: {
    padding: 0
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  selectableContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10
  },
  background: ({ backgroundColor }: StylesProps) => ({
    background: `${backgroundColor}`,
    overflow: 'hidden',
    paddingTop: 60,
    paddingBottom: 50
  }),
  button: {
    marginBottom: 10,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    justifyContent: 'flex-start'
  },
  selectedButton: {
    border: 0,
    marginBottom: 10,
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    '&::hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  selectableInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
    alignItems: 'center',
    position: 'relative',
    borderTopLeftRadius: 200,
    borderBottomLeftRadius: 200,
    [theme.breakpoints.down('xs')]: {
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
      flexDirection: 'column'
    }
  },
  selectableImage: {
    height: '100%',
    maxHeight: 400,
    borderRadius: '50%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 20,
    width: 400,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 200
    }
  },
  subjectDescription: {
    padding: 20
  },
  selectableOverflowBackground: {
    backgroundColor: theme.palette.primary.main,
    transform: 'translateX(100%)',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  description: {
    marginTop: 10
  },
  selectableBody: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
