import { ThemeOptions } from '@material-ui/core/styles';
import 'swiper/swiper-bundle.css';
import { RubikFont } from '../fonts/rubik';
import { BUTTON_STYLES, INPUT_STYLES, TYPOGRAPHY_STYLES } from '../globals';

const baseThemeConfig: ThemeOptions = {
  overrides: {
    MuiInputBase: INPUT_STYLES,
    MuiButton: BUTTON_STYLES as any,
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1440px !important'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...RubikFont],
        html: {
          scrollBehavior: 'smooth'
        },
        a: {
          cursor: 'pointer',
          textDecoration: 'none'
        },
        ':root': {
          '--swiper-theme-color': 'white'
        }
      }
    }
  },
  typography: TYPOGRAPHY_STYLES
};

export { baseThemeConfig };
