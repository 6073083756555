export enum Languages {
  ES = 'es',
  EN = 'en'
}

export const DEFAULT_LANGUAGE: Languages = Languages.ES;

export enum FormatType {
  date = 'date',
  dateTime = 'dateTime',
  stringDate = 'stringDate'
}
