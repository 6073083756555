import { makeStyles } from '@material-ui/core/styles';

export const useProjectCarouselStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.common.black,
    marginBottom: 50,
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  headerTextContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'default'
  },
  headerSubtitle: {
    fontWeight: 'bold',
    letterSpacing: 5,
    textTransform: 'uppercase'
  },
  filterButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '10px 30px',
    marginTop: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 10
    }
  },
  swiperContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  swiper: {
    width: '100%'
  },
  slide: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    color: theme.palette.common.black
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  card: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  wrapperImage: {
    marginBottom: 10,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
    aspectRatio: '1',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  title:{
    fontWeight: 'bold'
  },
  description: {
    cursor: 'default',
    marginTop: 5,
    marginBottom: 'auto'
  },
  cardActions: {
    padding: 0
  },
  rightButton: {
    marginTop: 30,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: 0,
    height: 'fit-content',
    minHeight: '64px',
    minWidth: '32px',
    borderRadius: 0,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    fontSize: '1.8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  linkButton: {
    marginTop: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '10px 30px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  pagination: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sectionTitle: {
    cursor: 'default',
    color: '#f4f4f4',
    fontWeight: 'bold',
    letterSpacing: 15,
    fontSize: '7rem',
    textTransform: 'uppercase',
    marginBottom: '-25px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));
