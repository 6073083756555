import { FormControl, FormHelperText, MenuItem, Select, SelectProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Controller, UseControllerProps, FieldError } from 'react-hook-form';
import { useControlledSelectStyles } from './ControlledSelect.styles';

interface Props<T> {
  controlProps: UseControllerProps<T>;
  selectProps: Omit<SelectProps, 'onChange' | 'value'>;
  values: string[];
  error?: FieldError;
}

export const ControlledSelect = <T,>(props:Props<T> ) => {
  const styles = useControlledSelectStyles();

  return (
    <Controller
      {...props.controlProps}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <Select
            {...props.selectProps}
            className={clsx(props.selectProps.className, !value ? styles.placeholder : '')}
            value={value}
            error={!!props.error}
            onChange={onChange}
          >
            <MenuItem disabled value="">
              {props.selectProps.placeholder}
            </MenuItem>
            {props.values.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!props.error}>{props.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
