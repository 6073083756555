import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../styles/variables/colors';

export const useModalStyles = makeStyles(({
  container: {
    overflow: 'scroll',
  },
  centerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  childrenContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.white,
    padding: 35,
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  closeButton: {
    marginLeft: 'auto',
    background: COLORS.light,
    color: COLORS.white,
    fontWeight: 'bold',
    fontSize: 20,
    padding: 0,
    minWidth: 37,
    textTransform: 'uppercase',
  },
}));
