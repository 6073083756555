import { makeStyles } from '@material-ui/core';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

export const useImageDescriptionListStyles = makeStyles((theme) => ({
  container: ({
    marginTop,
    marginBottom,
    tabletMarginBottom,
    tabletMarginTop,
    mobileMarginBottom,
    mobileMarginTop,
  }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
}));
