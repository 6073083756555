import { CardMedia, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MGNL_APP_BASE } from '../../../constants';
import { extractTextFromHtml, getDamImage } from '../../../helpers/AppHelpers';
import { News } from '../../../models/News';
import { StylesProps } from '../../../models/StylesProps';
import { useLatestNewsItemOddStyles } from './LatestNewsItem.styles';

interface Props extends StylesProps {
  item: News;
  isEven: boolean;
}

export const LatestNewsItem: React.VFC<Props> = (props) => {
  const styles = useLatestNewsItemOddStyles(props.isEven);
  const history = useHistory();
  const date = moment(props.item?.date).format('DD-MM-yyyy');
  const goToNew = (path: string) => () => {
    history.push(path.replace(MGNL_APP_BASE,''));
  };
  return (
    <section className={styles.container}>
      {props.item.preview && <CardMedia className={styles.image} image={getDamImage(props.item.preview)} />}
      <div className={styles.contentContainer} onClick={goToNew(props.item['@path'])}>
        <div className={styles.infoContainer}>
          <p className={styles.newLabel}>nuevo</p>
          {props.item.date && <p className={styles.dateLabel}>{date}</p>}
        </div>
        <div className={styles.descriptionContainer}>
          <Typography variant="subtitle2">{props.item.title}</Typography>
          <p className={styles.description}>{extractTextFromHtml(props.item.main['0']?.text)}</p>
          <span className={styles.authorContainer}>
            Por: <span className={styles.author}>{props.item.author}</span>
          </span>
        </div>
        <p className={styles.link}>+</p>
      </div>
    </section>
  );
};
