import { makeStyles } from '@material-ui/core/styles';

export const useNewItemListStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 0',
    color: theme.palette.common.black
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    }
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    marginTop: 10
  },
  author: {
    marginTop: 10,
  },
  image: {
    maxWidth: 150,
    height: '100%',
    aspectRatio: '1/1',
    objectFit: 'contain',
    objectPosition: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
    }
  }
}));
