import { Container } from '@material-ui/core';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import Slide, { SlideProps } from '../slide/Slide';
import GenerateImagesCarousel from './components/GenerateImagesCarousel';
import useImagesCarousel from './ImageCarrousel.style';

interface ImagesCarouselProps extends SlideProps, StylesProps {
  images: {
    '@nodes': string[];
  };
}

export const ImagesCarousel: React.VFC<ImagesCarouselProps> = (props) => {
  const slideNodes: string[] = props.images['@nodes'];
  const classes = useImagesCarousel({ ...props });

  const slideProps: SlideProps = {
    ...props,
    nodes: GenerateImagesCarousel({ slides: props.images, slideNodes: slideNodes })
  };

  return (
    <Container className={classes.root}>
      <Slide {...slideProps} />
    </Container>
  );
};

ImagesCarousel.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'false'
};
