import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ControlledCheckbox } from '../components/ControlledCheckbox/ControlledCheckbox';
import { ControlledTextField } from '../components/ControlledTextField/ControlledTextField';
import { contactFormDefaultValues, ContactFormModel } from '../form.constants';
import { useFormStyles } from '../form.styles';
import { emailRules } from './rules';

interface Props {
  modalForm?: boolean;
  onSubmit: (data: ContactFormModel) => void;
  form: UseFormReturn<ContactFormModel, object> ;
}

export const ContactForm: React.VFC<Props> = (props) => {
  const styles = useFormStyles();

  return (
    <>
      <Typography className={styles.title}>CONTÁCTANOS</Typography>
      <Typography className={styles.subtitle}>FACILÍTANOS TUS DATOS Y TE RESPONDEREMOS LO ANTES POSIBLE</Typography>
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu nombre', className: styles.input }}
        controlProps={{ name: 'name', rules: { required: true }, control: props.form.control, defaultValue: contactFormDefaultValues.name }}
        error={props.form.formState.errors.name}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tus apellidos', className: styles.input }}
        controlProps={{ name: 'surname', rules: { required: true }, control: props.form.control, defaultValue: contactFormDefaultValues.surname }}
        error={props.form.formState.errors.surname}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu dirección de correo electrónico', className: styles.input, type: 'email' }}
        controlProps={{
          name: 'email',
          rules: emailRules,
          control: props.form.control,
          defaultValue: contactFormDefaultValues.email
        }}
        error={props.form.formState.errors.email}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Facilítanos un teléfono de contacto', className: styles.input, type: 'tel' }}
        controlProps={{ name: 'phone', rules: { required: true }, control: props.form.control, defaultValue: contactFormDefaultValues.phone }}
        error={props.form.formState.errors.phone}
      />
      <ControlledTextField
        textFieldProps={{
          placeholder: 'Descríbenos el motivo de tu consulta',
          className: styles.textArea,
          minRows: 10,
          variant: 'outlined',
          multiline: true
        }}
        controlProps={{
          name: 'message',
          rules: { required: true, maxLength: 5000 },
          control: props.form.control,
          defaultValue: contactFormDefaultValues.message
        }}
        error={props.form.formState.errors.message}
      />
      <ControlledCheckbox
        labelProps={{ label: 'He leído y acepto los términos de uso y la política de privacidad.' }}
        controlProps={{ name: 'gdprAccept', rules: { required: true }, control: props.form.control, defaultValue: contactFormDefaultValues.gdprAccept }}
        error={props.form.formState.errors.gdprAccept}
      />
      <Button color="primary" onClick={props.form.handleSubmit(props.onSubmit)} variant="contained" className={styles.submitButton}>
        Enviar formulario
      </Button>
    </>
  );
};
