import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { responsiveContainerMargins } from '../../styles/globals';

export const useVideosCarouselStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: (props: StylesProps) => ({
    ...responsiveContainerMargins(theme, props),
    width: '100%'
  }),
  swiperContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .swiper-container': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }
  },
  swiper: {
    '& .swiper-wrapper': {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-evenly'
      }
    },
  },
  image: {
    width: '100%',
    position: 'relative'
  },
  slide: {
    display: 'flex',
    flexDirection: 'column'
  },
  playButton: {
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 0,
    color:'inherit',
    '&:hover': {
      borderRadius: 0,
      opacity: 0.9,
    }
  },
  playIcon: {
    position: 'absolute'
  },
  modal: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoPlayer: {
    position: 'absolute',
    margin: 'auto'
  },
  navigationContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center'
  },
  disabledNavigation: {
    cursor: 'default',
    opacity: 0.2
  },
  navigation:{
    userSelect: 'none',
    cursor: 'pointer',
    fontSize: 20,
    color: theme.palette.common.white,
    '&:nth-of-type(1)': {
      paddingRight: 5,
    },
    '&:nth-of-type(3)': {
      paddingLeft: 5,
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  logo: {
    marginRight: 10,
  }
}));
