import { SeoModel } from './models/SeoModel';

export const SEO_BASE_CONFIG: SeoModel = {
  seoBaseTitle: 'INNOCV SOLUTIONS',
  seoTitle: '',
  seoDescription:
    'INNOCV Solutions es una empresa especializada en creación de valor mediante proyectos de Digitalización y Transformación Digital aportando desarrollo de software de alta calidad y valor añadido. Ofrece servicios y soluciones digitales como desarrollos de aplicaciones móviles y web a medida, integraciones de sistemas, migraciones de código e infraestructura, soluciones de la Supply Chain, consultoría de procesos y tecnología con implantaciones de Celonis, Salesforce, Mulesoft y arquitecturas Cloud.',
  seoKeywords: '',
  seoCanonical: '',
  seoRobots: ''
};
