import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, marginLeftAndRight } from '../../styles/globals';

export const useButtonListStyles = makeStyles<Theme, StylesProps>((theme) => ({
  row: ({
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    tabletMarginBottom,
    tabletMarginTop,
    tabletMarginLeft,
    tabletMarginRight,
    mobileMarginBottom,
    mobileMarginTop,
    mobileMarginLeft,
    mobileMarginRight
  }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    ...marginLeftAndRight(marginLeft, marginRight),
    display: 'flex',
    width: '100%',
    '&[data-align="space-between"]': {
      justifyContent: 'space-between'
    },
    '&[data-align="space-evenly"]': {
      justifyContent: 'space-evenly'
    },
    '&[data-align="center"]': {
      justifyContent: 'center'
    },
    '&[data-align="right"]': {
      justifyContent: 'flex-end'
    },
    '&[data-align="left"]': {
      justifyContent: 'flex-start'
    },
    '&[flex-wrap="initial"]': {
      flexWrap: 'initial'
    },
    '&[flex-wrap="wrap"]': {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom),
      ...marginLeftAndRight(mobileMarginLeft, mobileMarginRight)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom),
      ...marginLeftAndRight(tabletMarginLeft, tabletMarginRight)
    }
  })
}));
