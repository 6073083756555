import { Box, CardMedia, IconButton } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import React from 'react';
import { getMagnoliaImage } from '../../../helpers/AppHelpers';
import { CustomerOpinion } from '../../../models/CustomerOpinion';
import { MagnoliaJcrMultiField } from '../../../models/magnolia/MagnoliaJcrMultiField';
import { ButtonList, ButtonListProps } from '../../button_list/ButtonList';
import RichText from '../../rich_text/RichText';
import VideoDialog from '../../video_dialog/VideoDialog';
import { useCustomerOpinionsStyles } from '../CustomerOpinions.styles';

interface GenerateCustomerOpinions {
  slides: MagnoliaJcrMultiField;
}

export const GenerateCustomerOpinions = (props: GenerateCustomerOpinions) => {
  const classes = useCustomerOpinionsStyles();
  const { slides } = props;
  const slideNodes: string[] = slides['@nodes'];

  return slideNodes.map((name: string) => {
    const customerOpinion: CustomerOpinion = slides[name];
    const buttons: MagnoliaJcrMultiField = customerOpinion.buttons;
    const buttonListProps: ButtonListProps = {
      button: buttons,
      buttonPosition: 'left',
      flexWrap: 'initial',
      marginBottom: '0',
      marginTop: '35'
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Box className={classes.root} key={name}>
        <div className={classes.constainerDescriptions}>
          <RichText text={customerOpinion.descriptions ?? ''} />
        </div>
        <div className={classes.video}>
          <CardMedia className={classes.image} image={getMagnoliaImage(customerOpinion.imageVideo)}>
            {customerOpinion?.isVideo && customerOpinion.urlVideo && (
              <IconButton aria-label="delete" color="inherit" onClick={() => handleClickOpen()}>
                <PlayArrowRoundedIcon className={classes.butonPlay} />
              </IconButton>
            )}
          </CardMedia>
          {customerOpinion?.isVideo && customerOpinion?.urlVideo && open && (
            <VideoDialog urlVideo={customerOpinion.urlVideo ?? null} open={open} onClose={handleClose} />
          )}
        </div>

        <div className={classes.constainerInformations}>
          <RichText text={customerOpinion.informations ?? ''} />
          {buttons['@nodes'].length !== 0 && <ButtonList {...buttonListProps} />}
        </div>
      </Box>
    );
  });
};
