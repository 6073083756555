import { makeStyles } from '@material-ui/core';

export const useImageDescriptionItemStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  image: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 30
  }
}));
