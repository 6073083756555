import React from 'react';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';

interface ImageProps extends LazyLoadImageProps {
  image: MagnoliaImage;
}

export const LazyImage: React.VFC<ImageProps> = ({ image, ...props }) => (
  <LazyLoadImage {...props} src={getMagnoliaImage(image['@id'] ?? image)} alt={image['@name'] ?? ''} effect="blur" delayTime={100} />
);
