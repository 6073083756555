import { makeStyles } from '@material-ui/core/styles';

export const useNewsListStyles = makeStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: 30,
  },
  title: {
    fontWeight: 'bold'
  }
});
