import { makeStyles } from '@material-ui/core/styles';

export const useTechnologyCarouselStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    marginBottom: 50,
    marginTop: 50,
    cursor: 'default',
  },
  headerSubtitle: {
    fontWeight: 'bold',
    letterSpacing: 5,
    textTransform: 'uppercase'
  },
  swiper: {
    width: '100%',
  },
  swiperContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  slide: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    color: theme.palette.common.white
  },
  technologyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image:{
    marginBottom: 30,
  },
  description: {
    cursor: 'default',
    marginTop: 'auto',
    paddingLeft: 70,
    paddingRight: 70,
  },
  downloadButton: {
    color: theme.palette.common.white,
    width: 'fit-content',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    borderRadius: '100%',
    padding: '10px',
    marginTop: 30
  },
  downloadIcon: {
    color: theme.palette.common.white
  },
  linkButton: {
    marginTop: 30,
    color: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    borderRadius: 30,
    padding: '5px 30px'
  },
  divider: {
    width: 1,
    height: '100%',
    background: '#999999'
  },
  pagination: {
    marginTop: 50,
    marginBottom: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
