import { EditableArea } from '@magnolia/react-editor';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { StylesProps } from '../../../models/StylesProps';
import { BackgroundColor } from '../../../styles/types/color';
import { useGridItemStyles } from './GridItem.style';

export interface GridItemProps extends StylesProps {
  isDivider?: string;
  isTableDivider?: string;
  isMobileDivider?: string;
  dividerColor?: BackgroundColor;
  tableDividerColor?: BackgroundColor;
  mobileDividerColor?: BackgroundColor;
  alignItems?: CSSProperties['alignItems'];
  textAlign?: CSSProperties['textAlign'];
  tableTextAlign?: CSSProperties['textAlign'];
  mobileTextAlign?: CSSProperties['textAlign'];
  metadata?: object;
  mainGridItem?: object;
}

export const GridItem: React.VFC<GridItemProps> = (props) => {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet: boolean = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up('lg'));

  const styles = useGridItemStyles({ ...props });
  const parentTemplateId = props.metadata['mgnl:template'];

  const showTabletDivider = JSON.parse(props.isTableDivider) && isTablet;
  const showDesktopDivider = JSON.parse(props.isDivider) && isDesktop;
  const showMobileDivider = JSON.parse(props.isMobileDivider) && isMobile;

  return (
    <div className={styles.column}>
      {props.mainGridItem && (
        <EditableArea className={styles.editableArea} content={props.mainGridItem} parentTemplateId={parentTemplateId} />
      )}
      {(showTabletDivider || showDesktopDivider || showMobileDivider) && (
        <Divider
          className={showMobileDivider ? styles.dividerHorizontal : styles.dividerVertical}
          orientation={showMobileDivider ? 'horizontal' : 'vertical'}
        />
      )}
    </div>
  );
};

GridItem.defaultProps = {
  isDivider: 'false',
  isTableDivider: 'false',
  isMobileDivider: 'false',
  dividerColor: 'none',
  tableDividerColor: 'none',
  mobileDividerColor: 'none',
  alignItems: 'start',
  textAlign: 'start',
  tableTextAlign: 'start',
  mobileTextAlign: 'start',
  paddingRight: '0',
  paddingLeft: '0',
  tabletPaddingRight: '0',
  tabletPaddingLeft: '0',
  mobilePaddingRight: '0',
  mobilePaddingLeft: '0'
};
