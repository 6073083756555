import { FilterColumn } from '../filter/constants';

export const sectorColumn: FilterColumn = { 
  title: 'SOLUCIÓN / SECTOR', 
  filters: [
    'Banca y seguros',
    'Retail',
    'Deporte y ocio',
    'Servicios',
    'RSC',
    'Salud e industria farmacéutica',
    'Logística y transporte',
    'Tecnología y telecomunicaciones',
    'Turismo / Hostelería',
    'Bienes de consumo',
    'Otros'
    ],
 };
export const productColumn: FilterColumn = {
  title: 'PRODUCTO',
  filters: [
    'Codeshift',
    'APP Móvil',
    'APP Web',
    'DITWUIT WMS',
    'DITPICK',
    'APP de fidelización',
    'Plataformas Streaming'
  ]
};
export const serviceColumn: FilterColumn = {
  title: 'SERVICIO',
  filters: [
    'Desarrollo software',
    'Equipos dedicados',
    'Migraciones',
    'Integraciones',
    'Arquitecturas',
    'Field Service',
    'Celonis',
    'Roadmap tecnológicos',
    'Supply Chain'
  ]
};

export const projectCarouselFilters: FilterColumn[] = [sectorColumn, productColumn, serviceColumn];
