import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { RESET_CONTAINER_STYLE, setBackgroundColor } from '../../styles/globals';

export const useActivitiesHeaderStyles = makeStyles((theme) => ({
  container: ({ marginTop, marginBottom, paddingTop, paddingBottom, backgroundColor }: StylesProps) => ({
    color: theme.palette.common.white,
    width: '100%',
    marginTop: `${marginTop}px`,
    marginBottom: `${marginBottom}px`,
    paddingTop: `${paddingTop}px`,
    paddingBottom: `${paddingBottom}px`,
    display: 'flex',
    flexDirection: 'column',
    ...setBackgroundColor(backgroundColor, theme),
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  }),
  triangle: {
    fontSize: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 7
    }
  },
  divider: {
    width: 1,
    background: theme.palette.common.white,
    height: '80%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    fontSize: '1.5rem',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  text: {
    position: 'relative'
  },
  listContainer: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    width: '100%',
    justifyContent: 'center'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    cursor: 'default'
  },
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
