import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

const selectedImageSize = {
  width: 860,
  height: 570
};
const selectedImageSm = {
  width: 410,
  height: 272
};
const selectedImageXs = {
  width: 300,
  height: 199
};
const itemImageSize = 100;
const itemImageSizeSm = 50;
const itemImageSizeXs = 34;
const paddingContainer = 30;
const imageSelectedMargin = 80;

export const useImageDetailStyles = makeStyles<Theme, StylesProps>((theme) => ({
  mainContainer: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    paddingTop: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom),
      padding: '0 16px'
    }
  }),
  mainWrapper: {
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: paddingContainer,
    boxSizing: 'border-box',
    marginTop: imageSelectedMargin - paddingContainer,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: paddingContainer * 2
    }
  },
  descriptionContainer: {
    marginRight: paddingContainer,
    [theme.breakpoints.down('md')]: {
      order: 2
    }
  },
  imagesContainer: {
    marginTop: -imageSelectedMargin,
    [theme.breakpoints.down('md')]: {
      order: 1,
      marginTop: 0
    }
  },
  selectedImage: {
    width: selectedImageSize.width,
    minWidth: selectedImageSize.width,
    maxWidth: selectedImageSize.width,
    height: selectedImageSize.height,
    minHeight: selectedImageSize.height,
    maxHeight: selectedImageSize.height,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('sm')]: {
      width: selectedImageSm.width,
      minWidth: selectedImageSm.width,
      maxWidth: selectedImageSm.width,
      height: selectedImageSm.height,
      minHeight: selectedImageSm.height,
      maxHeight: selectedImageSm.height
    },
    [theme.breakpoints.down('xs')]: {
      width: selectedImageXs.width,
      minWidth: selectedImageXs.width,
      maxWidth: selectedImageXs.width,
      height: selectedImageXs.height,
      minHeight: selectedImageXs.height,
      maxHeight: selectedImageXs.height
    }
  },
  imageList: {
    marginTop: 26,
    display: 'flex',
    justifyContent: 'center'
  },
  imageItem: {
    width: itemImageSize,
    minWidth: itemImageSize,
    maxWidth: itemImageSize,
    height: itemImageSize,
    minHeight: itemImageSize,
    maxHeight: itemImageSize,
    marginRight: 27,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'pointer',
    opacity: 0.8,
    transition: 'opacity 0.3s',
    [theme.breakpoints.down('sm')]: {
      width: itemImageSizeSm,
      minWidth: itemImageSizeSm,
      maxWidth: itemImageSizeSm,
      height: itemImageSizeSm,
      minHeight: itemImageSizeSm,
      maxHeight: itemImageSizeSm,
      marginRight: 10
    },
    [theme.breakpoints.down('xs')]: {
      width: itemImageSizeXs,
      minWidth: itemImageSizeXs,
      maxWidth: itemImageSizeXs,
      height: itemImageSizeXs,
      minHeight: itemImageSizeXs,
      maxHeight: itemImageSizeXs,
      marginRight: 10
    },
    '&.active, &:hover': {
      opacity: 1
    },
    '&:first-child': {
      marginRight: 26,
      [theme.breakpoints.down('sm')]: {
        marginRight: 10
      }
    },
    '&:last-child': {
      marginLeft: -1,
      marginRight: 0
    }
  }
}));
