export const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
export const MGNL_APP_BASE = process.env.REACT_APP_MGNL_APP_BASE ?? '';
export const MGNL_HOST = process.env.REACT_APP_MGNL_HOST ?? '';
export const MGNL_BASE_AUTHOR = process.env.REACT_APP_MGNL_BASE_AUTHOR ?? '';
export const MGNL_BASE_PUBLIC = process.env.REACT_APP_MGNL_BASE_PUBLIC ?? '';
export const MGNL_LANGUAGES = process.env.REACT_APP_MGNL_LANGUAGES ?? '';

export const MGNL_PROJECT_NAME = 'Innocv';
export const MGNL_API_PAGE_NAV = `/.rest/delivery/pagenav${MGNL_PROJECT_NAME}`;
export const MGNL_NODES = '/@nodes';
export const MGNL_API_PAGES = '/.rest/delivery/pages/v1';
export const MGNL_API_TEMPLATES = '/.rest/templateDefinition/v1';
export const MGNL_API_I18N = `/.rest/delivery/i18n${MGNL_PROJECT_NAME}/@nodes`;
