import { Container } from '@material-ui/core';
import React from 'react';
import { MagnoliaImageList } from '../../models/magnolia/MagnoliaImage';
import { StylesProps } from '../../models/StylesProps';
import { ImageDescriptionItem } from './components/ImageDescriptionItem';
import { useImageDescriptionListStyles } from './ImageDescriptionList.styles';

interface Props extends StylesProps {
  images: MagnoliaImageList;
}

export const ImageDescriptionList: React.VFC<Props> = ({ images, ...styleProps }) => {
  const styles = useImageDescriptionListStyles({ ...styleProps });
  return (
    <Container className={styles.container}>
      {images['@nodes'].map((key, index) => (
        <ImageDescriptionItem
          key={index}
          image={images[key].image}
          text={images[key].text}
          divider={images['@nodes'].length !== (index + 1)}
        />
      ))}
    </Container>
  );
};
