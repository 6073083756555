import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { getDamImage } from '../../helpers/AppHelpers';
import { ButtonModel } from '../../models/ButtonModel';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { ButtonComponent } from '../button/ButtonComponent';
import { Form } from '../forms/form';
import Icon from '../icon/Icon';
import { ModalRef } from '../modal/modal';
import { useButtonListStyles } from './ButtonList.style';

export interface ButtonListProps extends StylesProps {
  button: MagnoliaJcrMultiField;
  buttonPosition?: CSSProperties['justifyContent'];
  flexWrap?: string;
  containerClassName?: string;
}

export const ButtonList: React.FC<ButtonListProps> = (props) => {
  const classes = useButtonListStyles({ ...props });
  const modalRef = React.useRef<ModalRef>();
  const onClick = (button: ButtonModel) => () => {
    if (button.functionality) {
      modalRef.current?.toggleModal();
    }
  };

  
  return (
    <>
      <div className={clsx(classes.root, { [classes.removeContainer]: !JSON.parse(props.containerCheckbox) }, props.containerClassName)}>
        <Box className={classes.row} data-align={props.buttonPosition} flex-wrap={props.flexWrap}>
          {props.button['@nodes'].map((node) => {
            const buttonModel: ButtonModel = props.button[node];
            return (
              <div key={buttonModel['@id']}>
                {buttonModel.functionality && (
                  <Form
                    modalForm={true}
                    modalRef={modalRef}
                    template={buttonModel.functionality}
                    optionalArgs={{ file: buttonModel.image }}
                  />
                )}
                <ButtonComponent
                  isColorWhite={buttonModel.color === 'white'}
                  variant={buttonModel.variant}
                  color={buttonModel.variant === 'outlined' ? 'secondary' : 'primary'}
                  key={node}
                  startIcon={<Icon name={buttonModel.icon} />}
                  onClick={onClick(buttonModel)}
                  href={buttonModel.page}
                  target={buttonModel.target}
                >
                  {buttonModel.text}
                </ButtonComponent>
              </div>
            );
          })}
          {props.children}
        </Box>
      </div>
    </>
  );
};

ButtonList.defaultProps = {
  buttonPosition: 'center',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  flexWrap: 'initial',
  containerCheckbox: 'false'
};
