import { Box, Container } from '@material-ui/core';
import React from 'react';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import Slide, { SlideProps } from '../slide/Slide';
import { GenerateInnovations } from './components/GenerateInnovations';
import { default as useInnovationsPruebaStyles } from './Innovations.styles';

interface InnovationsProps extends SlideProps, StylesProps {
  slides: MagnoliaJcrMultiField;
  id?: string;
}

export const Innovations: React.VFC<InnovationsProps> = (props) => {
  const classes = useInnovationsPruebaStyles({
    marginBottom: props.marginBottom,
    marginTop: props.marginTop,
    tabletMarginBottom: props.tabletMarginBottom,
    tabletMarginTop: props.tabletMarginTop,
    mobileMarginBottom: props.mobileMarginBottom,
    mobileMarginTop: props.mobileMarginTop
  });

  const slideProps: SlideProps = {
    desktopSlides: props.desktopSlides,
    tabletSlides: props.tabletSlides,
    mobileSlides: props.mobileSlides,
    spaceBetween: props.spaceBetween,
    navigation: props.navigation,
    pagination: props.pagination,
    loop: props.loop,
    autoplay: props.autoplay,
    delay: props.delay,
    desktopRows: props.desktopRows,
    mobileRows: props.mobileRows,
    tabletRows: props.tabletRows,
    nodes: GenerateInnovations(props)
  };

  return (
    <Box id={props.id}>
      <Container className={classes.container}>
        <Slide {...slideProps} />
      </Container>
    </Box>
  );
};

Innovations.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
