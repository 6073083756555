import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { IconName } from '../../styles/types/IconName';
import { getIconComponent } from './icon.business';
import { useIconStyles } from './icon.styles';

interface IconProps {
  name: IconName;
  label?: string;
  className?: string;
}

const Icon: React.VFC<IconProps> = ({ name, label, className }) => {
  const styles = useIconStyles();
  const IconComponent = getIconComponent(name);

  return (
    IconComponent && (
      <Box className={clsx(styles.container, className)}>
        <IconComponent className={styles.icon} />
        {label && <Typography className={styles.label}>{label}</Typography>}
      </Box>
    )
  );
};

export default Icon;
