import { EditableArea } from '@magnolia/react-editor';
import { Box, Button, Container } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { MagnoliaEndpoints } from '../../constants';
import { getAPIPage } from '../../helpers/AppHelpers';
import { News } from '../../models/News';
import { StylesProps } from '../../models/StylesProps';
import { get } from '../../services/http.service';
import { LatestNewsItem } from './components/LatestNewsItem';
import { useLatestNewsStyles } from './LatestNews.styles';

interface Props extends StylesProps {
  content?: object;
}

export const LatestNews: React.FC<Props> = (props) => {
  const styles = useLatestNewsStyles({ ...props });
  const [news, setNews] = React.useState<News[]>([]);

  React.useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    try {
      const result = await get<News[]>(getAPIPage(MagnoliaEndpoints.NEWS, true), { baseURL: '' });
      setNews(result.data.slice(0, 3));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.background}>
      <Box className={styles.content}>{props.content && <EditableArea content={props.content} />}</Box>
      <Container className={clsx(styles.root, { [styles.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
      {props.children}
        <div className={styles.titleContainer}>
          <p className={styles.lastLabel}>Últimas</p>
          <p className={styles.newsLabel}>noticias</p>
        </div>
        <Box className={styles.news}>
          <article className={styles.newsArea}>
            {news.map((item: News, index: number) => (
              <LatestNewsItem item={item} isEven={!((index + 1) % 2)} key={index} />
            ))}
          </article>
        </Box>
        <Box className={styles.buttonContainer}>
          <Button className={styles.button} variant={'outlined'} color={'primary'} href={MagnoliaEndpoints.NEWS} onClick={null}>
            Ver más
          </Button>
        </Box>
      </Container>
    </div>
  );
};

LatestNews.defaultProps = {
  containerCheckbox: 'false',
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
