import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { IconName } from '../../styles/types/IconName';
import { ButtonList } from '../button_list/ButtonList';
import Icon from '../icon/Icon';
import { useImageCardStyles } from './ImageCard.styles';

interface Props {
  text: string;
  video: string;
  reverse: string;
  images?: MagnoliaJcrMultiField;
  buttons?: MagnoliaJcrMultiField;
  metadata: MagnoliaMetadata;
  icon?: IconName;
  iconLabel?: string;
}

export const ImageCard: React.VFC<Props> = (props) => {
  const styles = useImageCardStyles({ reverse: JSON.parse(props.reverse) });
  const paginationId = `pagination${props.metadata['@id']}`;
  return (
    <Container>
      <Box className={styles.container}>
        <Box className={styles.textContainer}>
          <Box dangerouslySetInnerHTML={{ __html: props.text }} />
          {props.icon && <Icon name={props.icon} label={props.iconLabel} className={styles.icon} />}
          {props.buttons && <ButtonList containerClassName={styles.buttonList} buttonPosition="left" button={props.buttons} />}
        </Box>
        <Box className={styles.swiperContainer}>
          <Swiper
            className={styles.swiper}
            style={{ maxWidth: '100%' }}
            pagination={{
              clickable: true,
              el: `#${paginationId}`,
              bulletClass: styles.bullet,
              bulletActiveClass: styles.activeBullet
            }}
            slidesPerGroup={1}
            slidesPerView={1}
            slidesPerColumn={1}
            slidesPerColumnFill="row"
          >
            {props.images['@nodes'].map((key, index) => (
              <SwiperSlide key={index}>
                <img
                  className={styles.image}
                  key={props.images[key].image['@name']}
                  src={getMagnoliaImage(props.images[key].image['@id'] ?? props.images[key].image)}
                  alt={props.images[key].image['@name'] ?? ''}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Box id={paginationId} className={styles.pagination} />
        </Box>
      </Box>
    </Container>
  );
};

ImageCard.defaultProps = {
  reverse: 'false'
};
