import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';


const useSnackbarMessageStyles = makeStyles(() => ({
  error: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: red[900],
      color: 'white'
    }
  }
}));

export default useSnackbarMessageStyles;
