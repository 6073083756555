import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import { compact, difference, first } from 'lodash';
import { MGNL_APP_BASE, MGNL_BASE_AUTHOR, MGNL_BASE_PUBLIC } from '../../constants';
import { inEditor } from '../../helpers/AppHelpers';
import { DEFAULT_LANGUAGE } from './locale.model';

const localeRegExp = new RegExp('^[A-Za-z]{2}([_-][A-Za-z]{2})?$');

export const customPathLocaleDetection: CustomDetector = {
  name: 'customPath',

  lookup() {
    const excludedPaths = [MGNL_APP_BASE, MGNL_BASE_AUTHOR, MGNL_BASE_PUBLIC].map((it) => it.replaceAll('/', ''));
    const firstPath = first(difference(compact(window?.location.pathname.split('/')), excludedPaths));
    const checkDefaultLang = () => (inEditor() ? DEFAULT_LANGUAGE : undefined);
    return localeRegExp.test(firstPath) ? firstPath : checkDefaultLang();
  }
};

const languageDetector = new LanguageDetector();

languageDetector.addDetector(customPathLocaleDetection);

export default languageDetector;
