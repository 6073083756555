import { FilterColumn } from '../filter/constants';

export const experienceColumn: FilterColumn = { title: 'EXPERIENCIA', filters: ['Trainee / Junior', 'Middle / Senior'] };
export const rolColumn: FilterColumn = {
  title: 'ROL',
  filters: ['Developer', 'DevOps', 'Quality Assurance', 'Product Owner', 'Team Leader', 'Technical Team Leader', 'HR', 'Marketing y ventas', 'Otros']
};
export const technologyColumn: FilterColumn = {
  title: 'TECNOLOGÍA',
  filters: ['Java', '.NET', 'Angular / React / Vue', 'DevOps', 'Phyton', 'iOS', 'Android', 'React Native', 'Data']
};

export const filterColumns: FilterColumn[] = [experienceColumn, rolColumn, technologyColumn];
