import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

export const useCardListStyles = makeStyles((theme) => ({
  root: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    display: 'flex',
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  })
}));
