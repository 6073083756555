import { Box, Container } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { Image } from '../image/Image';
import HamburgerButtom from './HamburgerButtom';
import useHeaderStyles from './Header.style';

export interface HeaderProps extends StylesProps {
  image: MagnoliaImage;
  multi: MagnoliaJcrMultiField;
  isOpen?: boolean;
  langs?: MagnoliaJcrMultiField;
}

const Header: React.VFC<HeaderProps> = ({ image, multi, imageBackground, langs }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const styles = useHeaderStyles({ imageBackground, isOpen });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const menuItemList = multi['@nodes']?.map((node, index) => {
    const itemNode = multi[node];
    return (
      <Link to={itemNode.page} key={index} onClick={handleClick}>
        {itemNode.text}
      </Link>
    );
  });

  const langsItem = langs && langs['@nodes']?.map((node, index) => {
    const itemNode = langs[node];
    return (
      <a href={itemNode?.page} key={index}>
        {itemNode?.text}
      </a>
    );
  });

  return (
    <div className={styles.toolbarContainer}>
      <Box className={styles.root}>
        <div className={styles.header}>
          <Container className={styles.headerContainer}>
            <div className={styles.imageContainer}>
              <Link to="/">
                <Image image={image} />
              </Link>
            </div>
            <HamburgerButtom isOpen={isOpen} handleClick={handleClick} />

            <nav>
              <div className={styles.linkArea}>
                {menuItemList}
                {langsItem}
              </div>
            </nav>
          </Container>
        </div>
      </Box>
    </div>
  );
};

export default Header;
