import { Box, Container } from '@material-ui/core';
import React from 'react';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { Image } from '../image/Image';
import Slide, { SlideProps } from '../slide/Slide';
import GeneratePartnerBannerProps from './components/GeneratePartnerBanner';
import usePartnerBannerStyles from './PartnerBanner.styles';

interface PartnerBannerProps extends SlideProps, StylesProps {
  image: MagnoliaImage;
  slides: MagnoliaJcrMultiField;
  id?: string;
}

const PartnerBanner: React.VFC<PartnerBannerProps> = (props) => {
  const classes = usePartnerBannerStyles({ ...props });
  const slideNodes: string[] = props.slides['@nodes'];
  const slideProps: SlideProps = {
    desktopSlides: props.desktopSlides,
    tabletSlides: props.tabletSlides,
    mobileSlides: props.mobileSlides,
    spaceBetween: props.spaceBetween,
    navigation: props.navigation,
    pagination: props.pagination,
    loop: props.loop,
    autoplay: props.autoplay,
    delay: props.delay,
    desktopRows: props.desktopRows,
    tabletRows: props.tabletRows,
    mobileRows: props.mobileRows,
    nodes: GeneratePartnerBannerProps({ slides: props.slides, slideNodes })
  };

  return (
    <Box id={props.id}>
      <Container className={classes.container}>
        <div className={classes.root}>
          <div className="row">
            <div className="imageColumn">{<Image image={props.image} />}</div>
            <div className="partnersColumn">
              <div className="richText">
                <Slide {...slideProps} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

PartnerBanner.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};

export default PartnerBanner;
