import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { useSectionStyles } from './TemplateSection.style';

interface TemplateSectionProps extends StylesProps {
  sectionTitle?: string;
  titlePosition: string;
}

export const TemplateSection: React.FC<TemplateSectionProps> = (props) => {
  const styles = useSectionStyles({ ...props });
  return (
    <div className={styles.container}>
      {props.sectionTitle && (
        <Container>
          <Typography className={styles.sectionTitle} data-align={props.titlePosition}>
            {props.sectionTitle}
          </Typography>
        </Container>
      )}
      <Box className={styles.root}>
        <Box>{props.children}</Box>
      </Box>
    </div>
  );
};

TemplateSection.defaultProps = {
  backgroundColor: 'none',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
