import { Divider } from '@material-ui/core';
import React from 'react';
import { extractTextFromHtml, getDamImage } from '../../../helpers/AppHelpers';
import { News } from '../../../models/News';
import { useNewItemListStyles } from './NewsItemList.styles';

interface Props {
  news: News;
  bottomDivider?: boolean;
}

export const NewsItemList: React.VFC<Props> = (props) => {
  const styles = useNewItemListStyles();

  return (
    <>
      <a href={props.news['@path']} className={styles.container}>
        <img className={styles.image} src={getDamImage(props.news.image)} />
        <div className={styles.titleContainer}>
          <div className={styles.title}>{props.news.title}</div>
          <div className={styles.description}>{extractTextFromHtml(props.news?.main['0']?.text)}</div>
          <div className={styles.author}>Por:{props.news.author}</div>
        </div>
      </a>
      {props.bottomDivider && <Divider />}
    </>
  );
};
