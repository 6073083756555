import { Box, Container } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { Form } from '../../components/forms/form';
import { FormTemplate } from '../../components/forms/form.constants';
import { Image } from '../../components/image/Image';
import RichText from '../../components/rich_text/RichText';
import { Routes } from '../../constants';
import { Layout } from '../../layout/Layout';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import useNewsDetailStyles from './JobOffer.styles';

interface Props {
  header?: object;
  footer?: object;
  title: string;
  subtitle: string;
  image?: MagnoliaImage;
  description: string;
  metadata?: MagnoliaMetadata;
}

export const JobOffer: React.VFC<Props> = (props) => {
  const styles = useNewsDetailStyles();

  return (
    <Layout>
      <Container>
        <Box className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <RichText text={props.title} />
            <RichText text={props.subtitle} />
            <RichText text={props.description} />
          </div>
          <div className={styles.imageContainer}>
            {props.image && <Image image={props.image} wrapperClassName={styles.image} />}
            <Form optionalArgs={{ offerId: props.metadata['@id'], offerTitle: props.title }} template={FormTemplate.CV} />
          </div>
        </Box>
        <div className={styles.buttonContainer}>
          <a href={Routes.JOIN} className={styles.buttonBack}>
            <ArrowBack />
            <span>Volver al listado de ofertas</span>
          </a>
        </div>
      </Container>
    </Layout>
  );
};
