import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText } from '@material-ui/core';
import React from 'react';
import { Controller, FieldError, UseControllerProps } from 'react-hook-form';
import { useControlledCheckboxStyles } from './ControlledCheckbox.styles';

interface Props<T> {
  controlProps: UseControllerProps<T>;
  labelProps: Omit<FormControlLabelProps, 'control'>;
  checkboxProps?: Omit<CheckboxProps, 'onChange' | 'value'>;
  error?: FieldError;
}

export const ControlledCheckbox = <T,>(props: Props<T>) => {
  const styles = useControlledCheckboxStyles();
  return (
    <FormControl error={!!props.error}>
      <FormControlLabel
        {...props.labelProps}
        control={
          <Controller
            {...props.controlProps}
            render={({ field: { onChange, value } }) => (
              <Checkbox {...props.checkboxProps} checked={value} onChange={onChange} value={value} />
            )}
          />
        }
      />
      <FormHelperText className={styles.error} error={!!props.error}>
        {props.error?.type === 'required' ? 'Campo requerido' : props.error?.message}
      </FormHelperText>
    </FormControl>
  );
};

ControlledCheckbox.defaultProps = {
  checkboxProps: {}
};
