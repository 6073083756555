import { Container } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import useRichTextStyles from './RichText.styles';

interface RichTextProps extends StylesProps {
  text: string;
  id?: string;
}

const RichText: React.VFC<RichTextProps> = (props) => {
  const classes = useRichTextStyles({ ...props });

  return (
    <Container id={props.id} className={clsx(classes.root, { [classes.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
      <div className={classes.richText} dangerouslySetInnerHTML={{ __html: props.text }} />
    </Container>
  );
};

RichText.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0',
  containerCheckbox: 'false',
  marginLeft: '0',
  marginRight: '0',
  tabletMarginRight: '0',
  tabletMarginLeft: '0',
  mobileMarginRight: '0',
  mobileMarginLeft: '0'
};

export default RichText;
