import { AppBar } from '@material-ui/core';
import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { LatestNews } from '../components/latest_news/LatestNews';
import { useCommonNodes } from '../hooks/useCommonNode';

interface Props {
  content?: object;
  latestNewsChildren?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children, content, latestNewsChildren }) => {
  const commonNodes = useCommonNodes();

  return (
    <>
      {commonNodes?.header && (
        <AppBar position="sticky">
          <Header {...commonNodes.header} />
        </AppBar>
      )}

      {children}
      <LatestNews content={content}>{latestNewsChildren}</LatestNews>
      {commonNodes?.footer && <Footer {...commonNodes.footer} />}
    </>
  );
};
