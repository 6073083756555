import { makeStyles } from '@material-ui/core/styles';

export const usePresentationContentStyles = makeStyles((theme) => ({
  slide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto'
  },
  filterButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterButton: {
    background: '#AD506A',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  background: {
    background: theme.palette.primary.main,
    paddingTop: 30,
    paddingBottom: 30
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40
  },
  teamtailor: {
    '& .teamtailor-jobs__filters': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& .teamtailor-jobs__select-wrapper': {
        float: 'none',
        '& > select.teamtailor-jobs__select': {
          background: '#AD506A',
          border: 'none',
          borderRadius: 30,
          color: theme.palette.common.white,
          fontSize: 16,
          fontWeight: 600,
          padding: '15px 10px',
          borderRight: 'solid 10px #AD506A',
          '&:focus': {
            outline: 'none'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        marginBottom: 25,
        '& .teamtailor-jobs__select-wrapper': {
          width: '100%',
          '& > select.teamtailor-jobs__select': {
            width: '100%'
          }
        }
      }
    },
    '& .teamtailor-jobs__job-wrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      color: '#fff',
      alignItem: 'strech',
      justifyContent: 'space-between',
      '& a': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 10
      },
      '& .teamtailor-jobs__pagination': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& a': {
          fontSize: 18,
          '&.teamtailor-jobs__pagination__next': {
            marginLeft: 'auto'
          }
        }
      },
      '& .teamtailor-jobs__job': {
        position: 'relative',
        boxSizing: 'border-box',
        margin: 0,
        width: 'calc(100% / 3 - 1px)',
        padding: 20,
        marginBottom: 20,
        borderBottom: 'solid 1px #fff',
        textAlign: 'center',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          right: 0,
          top: 0,
          width: 1,
          backgroundColor: '#fff',
          height: 'calc(100% - 20px)'
        },
        '&:nth-child(3n)::after': {
          display: 'none'
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& .teamtailor-jobs__job': {
          width: '100%',
          '&::after': {
            display: 'none!important'
          }
        }
      }
    }
  }
}));
