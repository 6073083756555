import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  isOpen: boolean;
}

const useHamburgerButtomtyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  topHamburger: ({ isOpen }: StyleProps) => ({
    position: 'absolute',
    width: 24,
    height: 1,
    borderRadius: 2,
    background: '#fff',
    transform: isOpen ? 'rotate(0deg) translateY(-6px)' : 'rotate(45deg) translateY(0px)',
    transition: 'transform ease 0.5s'
  }),
  mediumHamburger: ({ isOpen }: StyleProps) => ({
    width: 24,
    height: 1,
    borderRadius: 2,
    background: '#fff',
    transform: isOpen ? 'scaleX(1)' : 'scaleX(0)',
    transition: 'transform ease 0.5s'
  }),
  bottomHamburger: ({ isOpen }: StyleProps) => ({
    position: 'absolute',
    width: 24,
    height: 1,
    borderRadius: 2,
    background: '#fff',
    transform: isOpen ? 'rotate(0deg) translateY(6px)' : 'rotate(131deg) translateY(0px)',
    transition: 'transform ease 0.5s'
  })
}));

export default useHamburgerButtomtyles;
