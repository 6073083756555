import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../../models/StylesProps';
import { marginBottomAndTop, paddingLeftAndRight, setBackgroundColor } from '../../../styles/globals';
import { GridItemProps } from './GridItem';

export const useGridItemStyles = makeStyles<Theme>((theme) => ({
  column: ({ alignItems, textAlign, tableTextAlign, mobileTextAlign }: GridItemProps) => ({
    width: '100%',
    display: 'flex',
    alignItems: alignItems,
    justifyContent: 'space-between',
    textAlign: textAlign,
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: tableTextAlign
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      textAlign: mobileTextAlign
    }
  }),
  editableArea: (props: StylesProps) => ({
    width: '100%',
    ...paddingLeftAndRight(props.paddingLeft, props.paddingRight),
    [theme.breakpoints.between('xs', 'md')]: {
      ...paddingLeftAndRight(props.tabletPaddingLeft, props.tabletPaddingRight)
    },
    [theme.breakpoints.only('xs')]: {
      ...paddingLeftAndRight(props.mobilePaddingLeft, props.mobilePaddingRight)
    }
  }),
  dividerVertical: ({ dividerColor, tableDividerColor }: GridItemProps) => ({
    ...setBackgroundColor(dividerColor, theme),
    [theme.breakpoints.between('xs', 'md')]: {
      ...setBackgroundColor(tableDividerColor, theme)
    },
    width: 1,
    height: 'auto',
    alignSelf: 'stretch'
  }),
  dividerHorizontal: ({ mobileDividerColor }: GridItemProps) => ({
    ...setBackgroundColor(mobileDividerColor, theme),
    width: 130,
    height: 1,
    alignSelf: 'center',
    ...marginBottomAndTop('24', '24'),
    [theme.breakpoints.only('xs')]: {
      width: 260
    }
  })
}));
