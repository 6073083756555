const getRowIndexWithoutPagination = (index: number, numberOfSlides: number): number => {
  const page = Math.floor(index / numberOfSlides);
  const fixedIndex = index - page * numberOfSlides;
  return fixedIndex;
};

export const showRightDivider = (isDesktop: boolean, index: number, numberOfSlides: number): boolean => {
  const rowIndex = getRowIndexWithoutPagination(index, numberOfSlides);
  return isDesktop && !(rowIndex % 3 === 0 && index !== numberOfSlides - 1);
};

export const showBottomDivider = (isDesktop: boolean, index: number, numberOfSlides: number): boolean => {
  const rowIndex = getRowIndexWithoutPagination(index, numberOfSlides);
  return isDesktop ? !(rowIndex % 7 === 0 || rowIndex % 8 === 0 || rowIndex % 9 === 0) : true;
};
