import { Box, Container, Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { ButtonList } from '../button_list/ButtonList';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import { useBoxSectionStyles } from './BoxSection.style';

interface BoxSectionProps extends StylesProps {
  box: {
    '@id': string;
    '@name': string;
    '@nodeType': string;
    '@nodes': string[];
    '@path': string;
  };
  bottomDivider?: string;
}

export const BoxSection: React.VFC<BoxSectionProps> = (props) => {
  const classes = useBoxSectionStyles({ ...props });
  return (
    <Container className={clsx(classes.root, { [classes.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
      <Box className={classes.row}>
        {props.box['@nodes'].map((node) => {
          const itemNode = props.box[node];
          return (
            <Box className={classes.column} key={node}>
              {itemNode.image && <Image image={itemNode.image} />}
              {itemNode.text && <RichText text={itemNode.text} />}
              {itemNode.buttons && <ButtonList button={itemNode.buttons} />}
            </Box>
          );
        })}
      </Box>
      {JSON.parse(props.bottomDivider) && <Divider className={classes.divider} />}
    </Container>
  );
};

BoxSection.defaultProps = {
  bottomDivider: 'false',
  containerCheckbox: 'false',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
