import { Project } from '../../models/Project';
import { productColumn, sectorColumn, serviceColumn } from './ProjectCarousel.constants';

export const filterProjects = (projects: Project[], filters: Record<string, string[]>) => {
  if (!filters) {
    return fillArray(projects);
  }

  const sector = filters[sectorColumn.title];
  const product = filters[productColumn.title];
  const service = filters[serviceColumn.title];

  const filteredProjects = projects.reduce((acc, project) => {
    const includesSector = !!sector?.length ? sector.includes(project.sector) : true;
    const includesProduct = !!product?.length ? product.includes(project.product) : true;
    const includesService = !!service?.length ? service.includes(project.service) : true;
    const shouldAddProject = includesSector && includesProduct && includesService;
    return shouldAddProject ? [...acc, project] : acc;
  }, []);

  return fillArray(filteredProjects);
};

const fillArray = (projects: Project[]) => {
  const nextMultiple = Math.ceil(projects.length / 4) * 4 - projects.length;
  return projects.length > 4 ? [...projects, ...Array(nextMultiple).fill(null)] : projects;
};


export const getSlideNumber = (isDesktop: boolean, isTable: boolean): number => {
 if(isDesktop) { 
   return 4;
 }

 return isTable ? 2 : 1;
};