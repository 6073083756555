import { makeStyles } from '@material-ui/core/styles';
import { CardStylesProps } from '../../models/CardStylesProps';

export const useCardItemStyles = makeStyles((theme) => ({
  root: ({
    heightInformation,
    marginInformationTop,
    marginInformationLeft,
    marginInformationRight,
    marginLeftOfDate,
    topOfDate,
    marginLeftOfLink,
    topOfLink,
    topOfIcon,
    marginLeftOfIcon,
    mobileHeightInformation,
    mobileMarginInformationLeft,
    mobileMarginInformationRight,
    mobileMarginInformationTop,
    mobileLeftOfDate,
    backgroundCardColor,
    boxShadow
  }: CardStylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    paddingTop: 50,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '& .container-image': {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 328,
      height: 429,
      alignItems: 'center',
      marginRight: 69,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 244,
        height: 319,
        marginRight: 35,
        '& img': {
          maxWidth: '100% !important'
        }
      },
      '& .icon': {
        background: '#F17680',
        width: 128,
        height: 128,
        borderRadius: '100px ',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: `${topOfIcon}px`,
        left: `${marginLeftOfIcon}px`,
        [theme.breakpoints.down('xs')]: {
          width: 97,
          height: 97,
          '& img': {
            width: '80% !important',
            marginLeft: 6
          }
        }
      }
    },
    '& .container-informations': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      boxShadow: `${boxShadow}`,
      height: `${heightInformation}%`,
      background: `${backgroundCardColor}`,
      color: '#333333',
      padding: 40,
      marginTop: `${marginInformationTop}px`,
      marginLeft: `${marginInformationLeft}px`,
      marginRight: `${marginInformationRight}px`,
      [theme.breakpoints.down('xs')]: {
        height: `${mobileHeightInformation}%`,
        padding: 25,
        marginTop: `${mobileMarginInformationTop}px`,
        marginLeft: `${mobileMarginInformationLeft}px`,
        marginRight: `${mobileMarginInformationRight}px`
      },
      '& .container-date': {
        background: '#F8B18E',
        width: 'max-content',
        fontWeight: 'bold',
        fontSize: 12,
        color: 'white',
        padding: '5px 12px',
        position: 'absolute',
        left: `${marginLeftOfDate}px`,
        top: `${topOfDate}px`,
        [theme.breakpoints.down('xs')]: {
          fontSize: 9,
          padding: '3px 10px',
          right: -22,
          left: `${mobileLeftOfDate}px`
        }
      },
      '& .container-descriptions': {
        display: 'block',
        maxWidth: '100%',
        height: '100%',
        margin: '0 auto',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          height: 240
        }
      },
      '& .container-buttons': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          margin: '0 -32px'
        }
      },
      '& a': {
        color: '#fff',
        fontWeight: 'bold'
      },
      '& .link': {
        fontSize: 38,
        background: '#f8b18e',
        width: 45,
        height: 91,
        borderRadius: '0 100px 100px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: `${topOfLink}px`,
        left: `${marginLeftOfLink}%`,
        [theme.breakpoints.down('xs')]: {
          width: 33,
          height: 70,
          right: -35,
          fontSize: 22
        }
      }
    }
  }),
  rightDivider: {
    height: '80%',
    width: 1,
    background: '#999999'
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
