import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const defaultConfig = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 10000
};

export const axiosInstance = axios.create({ baseURL: baseUrl });


export const get = async <T>(endPoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return await axiosInstance.get(endPoint, { ...defaultConfig, ...config });
};

export const post = async <D, T>(endPoint: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return await axiosInstance.post(endPoint, data, { ...defaultConfig, ...config });
};

export const put = async <D, T>(endPoint: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return await axiosInstance.put(endPoint, data, { ...defaultConfig, ...config });
};

export const remove = async <T>(endPoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return await axiosInstance.delete(endPoint, { ...defaultConfig, ...config });
};

export const addTokenInterceptor = (token: string) => {
  return axiosInstance.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    },
    Promise.reject
  );
}
