import { EditorContextHelper } from '@magnolia/react-editor';
import { History } from 'history';
import i18n from 'i18next';
import {
  MGNL_API_PAGE_NAV,
  MGNL_APP_BASE,
  MGNL_BASE_AUTHOR,
  MGNL_BASE_PUBLIC,
  MGNL_HOST,
  MGNL_LANGUAGES,
  MGNL_NODES,
  PUBLIC_URL
} from '../constants';
import { ImageType } from '../models/ImageType';
import { get } from '../services/http.service';

export function inDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function inEditor() {
  return EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview();
}

export function inEditorOrAuthorMode() {
  return inEditor() || window.location.href.indexOf(MGNL_BASE_AUTHOR) > 0;
}

export function getAPIBase(): string {
  const path = inDevelopment() || inEditorOrAuthorMode() ? MGNL_BASE_AUTHOR : MGNL_BASE_PUBLIC;
  return MGNL_HOST + path;
}

export function getDamImage(jcr: string): string {
  return `${getAPIBase()}/dam/${jcr}`;
}

export function getLanguages(): string[] {
  return MGNL_LANGUAGES?.split(' ') ?? [];
}

export function getRouterBasename(): string {
  const nodeName = MGNL_APP_BASE ?? '';
  const pathname = window.location.pathname;
  return pathname.indexOf(nodeName) > -1 ? pathname.replace(new RegExp(nodeName + '.*'), '') + nodeName : '/';
}

export function removeCurrentLanguage(text: string) {
  const currentLanguage = getCurrentLanguage();
  const value = text.replace(new RegExp(`/${currentLanguage}($|/)`), '/');
  return removeLastSlash(value);
}

export function getCurrentLanguage(): string {
  return i18n.language;
}

export function localizeContent(url: string): string {
  const currentLanguage = getCurrentLanguage();
  const languages = getLanguages();

  if (currentLanguage !== languages[0]) {
    url = removeCurrentLanguage(url);
    url += (url.indexOf('?') > -1 ? '&' : '?') + 'lang=' + currentLanguage;
  }

  return url;
}

export const removeLastSlash = (text: string) => {
  return text?.endsWith('/') ? text.slice(0, -1) : text;
};

export const getImage = (image: string) => `${PUBLIC_URL}/images/${image}`;

export const getMagnoliaImage = (image: ImageType) => {
  return image['@link'] ?? getDamImage(image['@id'] ?? image);
};

export const getMagnoliaUrlImage = (image: ImageType) => `url(${getMagnoliaImage(image)})`;

export const getUrImage = (image: string) => `url(${getImage(image)})`;

export const getAPIPage = (page: string, nodes?: boolean) => {
  const apiBase = getAPIBase();
  return nodes ? apiBase + MGNL_API_PAGE_NAV + MGNL_APP_BASE + page + MGNL_NODES : apiBase + MGNL_API_PAGE_NAV + MGNL_APP_BASE + page;
};

export const getNodesFromPath = async <T,>(path: string): Promise<T[]> => {
  const response = await get<T>(getAPIPage(path, true), { baseURL: '' });
  return response?.data['@nodes'].map((key) => response.data[key]) ?? [];
};

export const extractTextFromHtml = (html: string): string => {
  if (!html) {
    return '';
  }
  let span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export const navigateToPage = (page: string, push: History['push']): void => {
  const path = page.split('/').filter((pathItem: string) => !!pathItem);
  path.shift();
  push(path.join('/'));
};

export const getCurrentPathname = (pathname: string): string => pathname?.substring(pathname.lastIndexOf('/'), pathname.length);

export const escapeHtml = (unsafe: string) => {
  return unsafe.replace(/(<([^>]+)>)/gi, '');
};
