import { Theme } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { StylesProps } from '../models/StylesProps';
import { BackgroundColor } from './types/color';

export const TYPOGRAPHY_STYLES: TypographyOptions = {
  fontFamily: [
    'Rubik',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Helvetica Neue"',
    'sans-serif',
    'Arial',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(', '),
  fontSize: 14,
  fontWeightRegular: 400
};

const disableFormFill = {
  transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s'
};
export const INPUT_STYLES = {
  root: {
    '& input': {
      '&:-webkit-autofill': disableFormFill,
      '&:-webkit-autofill:focus': disableFormFill,
      '&:-webkit-autofill:hover': disableFormFill
    }
  }
};
export const RESET_CONTAINER_STYLE = {
  paddingRight: 0,
  marginLeft: 0,
  marginRight: 0,
  paddingLeft: 0
};

const buttonPadding = '12px 30px';
export const BUTTON_STYLES = {
  root: {
    padding: buttonPadding,
    borderRadius: 30,
    textTransform: 'initial',
    fontSize: 16,
    '&.MuiButton-containedPrimary': {
      color: '#fff'
    }
  },
  outlined: {
    padding: buttonPadding
  },
  text: {
    padding: buttonPadding
  }
};

export const marginBottomAndTop = (top, bottom) => {
  return {
    marginTop: `${top}px !important`,
    marginBottom: `${bottom}px !important`
  };
};

export const marginLeftAndRight = (left, right) => {
  return {
    marginLeft: `${left}px !important`,
    marginRight: `${right}px !important`
  };
};

export const paddingBottomAndTop = (bottom, top) => {
  return {
    paddingTop: `${top}px !important`,
    paddingBottom: `${bottom}px !important`
  };
};

export const paddingLeftAndRight = (left, right) => {
  return {
    paddingLeft: `${left}px !important`,
    paddingRight: `${right}px !important`
  };
};

export const setBackgroundColor = (colorStyle: BackgroundColor, theme: Theme) => {
  switch (colorStyle) {
    case 'blueGradient':
      return { backgroundImage: 'linear-gradient(#325C7F, #6D5A7F, #BF6C86)' };
    case 'blueInverseGradient':
      return { backgroundImage: 'linear-gradient(#BF6C86, #6D5A7F, #325C7F)' };
    case 'blueInverseGradientTwoColors':
      return { backgroundImage: 'linear-gradient(#6D5A7F, #325C7F)' };
    case 'purpleGradient':
      return { backgroundImage: 'linear-gradient(#6D5A7F, #F17680)' };
    case 'lightcoral':
      return { backgroundImage: 'linear-gradient(#F17680, #F17680)' };
    case 'lightGray':
      return { backgroundImage: 'linear-gradient(#EEEEEE, #EEEEEE)' };
    case 'darkGray':
      return { backgroundImage: 'linear-gradient(#999999, #999999)' };
    case 'main':
      return { backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})` };
    case 'pink':
      return { backgroundImage: 'linear-gradient(#C06C86, #C06C86)' };
    default:
      return { backgroundImage: 'none' };
  }
};

export const responsiveContainerMargins = (theme: Theme, styles: Partial<StylesProps>) => {
  const {
    containerCheckbox = 'false',
    marginTop = '0',
    marginBottom = '0',
    mobileMarginTop = '0',
    mobileMarginBottom = '0',
    tabletMarginTop = '0',
    tabletMarginBottom = '0'
  } = styles;
  const horizontalMargins = JSON.parse(containerCheckbox) ? {} : RESET_CONTAINER_STYLE;
  return {
    ...horizontalMargins,
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  };
};
