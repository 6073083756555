import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { Form } from '../forms/form';
import { FormTemplate } from '../forms/form.constants';
import Icon from '../icon/Icon';
import { LazyImage } from '../image/LazyImage';
import RichText from '../rich_text/RichText';
import { useContactCardStyles } from './ContactCard.style';

export interface ContactCardProps extends StylesProps {
  metadata: object;
  image: MagnoliaImage;
  text: string;
  mapLink: string;
  offices?: MagnoliaJcrMultiField;
  footerContactFormImage?: MagnoliaImage;
  contactId?: string;
  mapId?: string;
}

export const ContactCard: React.VFC<ContactCardProps> = (props) => {
  const styles = useContactCardStyles({ ...props });

  return (
    <Box id={props.contactId}>
      <Box className={styles.container}>
        <Container className={styles.contactContainer}>
          {props.image && <LazyImage className={styles.image} image={props.image} />}
          <Box className={styles.text}>{props.text && <RichText text={props.text} />}</Box>
        </Container>
      </Box>
      <Container className={styles.formMapContainer} id={props.mapId}>
        <Box className={styles.formContainer}>
          <Typography variant="h5">Las instalaciones de INNOCV</Typography>
          <Typography variant="h4" className={styles.formTitle}>
            ¿Dónde estamos?
          </Typography>
          <ul className={styles.officeList}>
            {props.offices &&
              props.offices['@nodes'].map((node, index) => (
                <li key={index} className={styles.officeAddress}>
                  <Icon name="location" label={props.offices[node].address} />
                </li>
              ))}
          </ul>
          <Form containerClassName={styles.form} template={FormTemplate.Contact} />
          {props.footerContactFormImage && <LazyImage className={styles.footerContactFormImage} image={props.footerContactFormImage} />}
        </Box>
        <iframe src={props.mapLink} frameBorder={0} className={styles.map} width="100%" loading="lazy" />
      </Container>
    </Box>
  );
};
