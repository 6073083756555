import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ControlledCheckbox } from '../components/ControlledCheckbox/ControlledCheckbox';
import { ControlledSelect } from '../components/ControlledSelect/ControlledSelect';
import { ControlledTextField } from '../components/ControlledTextField/ControlledTextField';
import { downloadFormDefaultValues, DownloadFormModel, levelSelectValues, roleSelectValues } from '../form.constants';
import { useFormStyles } from '../form.styles';
import { emailRules } from './rules';

interface Props {
  modalForm?: boolean;
  onSubmit: (data: DownloadFormModel) => void;
  form: UseFormReturn<DownloadFormModel, object>;
}

export const DownloadForm: React.VFC<Props> = (props) => {
  const styles = useFormStyles();

  return (
    <>
      <Typography className={styles.title}>CONÓCENOS MÁS</Typography>
      <Typography className={styles.subtitle}>DESCUBRE CÓMO NUESTRAS SOLUCIONES APORTAN VALOR A TU NEGOCIO</Typography>
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu nombre', className: styles.input }}
        controlProps={{
          name: 'name',
          rules: { required: true },
          control: props.form.control,
        }}
        error={props.form.formState.errors.name}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tus apellidos', className: styles.input }}
        controlProps={{
          name: 'surname',
          rules: { required: true },
          control: props.form.control,
        }}
        error={props.form.formState.errors.surname}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu dirección de correo electrónico', className: styles.input, type: 'email' }}
        controlProps={{
          name: 'email',
          rules: emailRules,
          control: props.form.control,
        }}
        error={props.form.formState.errors.email}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Empresa', className: styles.input }}
        controlProps={{
          name: 'company',
          rules: { required: true },
          control: props.form.control,
        }}
        error={props.form.formState.errors.surname}
      />

      <ControlledSelect
        values={roleSelectValues}
        selectProps={{ placeholder: 'Función empresarial', displayEmpty: true, className: styles.input }}
        controlProps={{
          name: 'role',
          rules: { required: false },
          control: props.form.control,
        }}
        error={props.form.formState.errors.role}
      />
      <ControlledSelect
        values={levelSelectValues}
        selectProps={{ placeholder: 'Nivel de puesto', displayEmpty: true, className: styles.input }}
        controlProps={{
          name: 'level',
          rules: { required: false },
          control: props.form.control,
        }}
        error={props.form.formState.errors.level}
      />

      <ControlledCheckbox
        labelProps={{ label: 'Acepto el manejo y almacenamiento de mis datos.' }}
        controlProps={{
          name: 'gdprAccept',
          rules: { required: true },
          control: props.form.control,
        }}
        error={props.form.formState.errors.gdprAccept}
      />
      <Button color="primary" onClick={props.form.handleSubmit(props.onSubmit)} variant="contained" className={styles.submitButton}>
        Descargar
      </Button>
    </>
  );
};
