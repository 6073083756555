import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop, RESET_CONTAINER_STYLE } from '../../styles/globals';

export const useLatestNewsStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: 'linear-gradient(#6D5A7F, #F17680)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleContainer: {
    margin: '40px 0px 60px 0'
  },
  lastLabel: {
    textAlign: 'center',
    letterSpacing: '4.58px',
    fontSize: 23,
    [theme.breakpoints.down('xs')]: {
      margin: '50px 0 50px 0'
    }
  },
  newsLabel: {
    margin: 0,
    letterSpacing: '4.58px',
    fontSize: 37,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  content: {
    width: '100%'
  },
  root: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    color: '#fff!important',
    //TODO: review functionality
    // ...marginBottomAndTop(marginTop, marginBottom),
    // [theme.breakpoints.down('xs')]: {
    //   ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    // },
    // [theme.breakpoints.only('md')]: {
    //   ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    // },
    marginBottom: '100px !important'
  }),
  news: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  newsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    marginBottom: 50
  },
  newsArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  button: {
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    padding: '8px 50px',
    '&:hover': {
      borderColor: theme.palette.common.white
    }
  }
}));
