import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import useLoadingStyles from './Loading.styles';

const Loading: React.VFC = () => {
  const classes = useLoadingStyles();

  return (
    <Box className={classes.root}>
      <Box className="wrapper">
        <CircularProgress size={50} />
      </Box>
    </Box>
  );
};

export default Loading;
