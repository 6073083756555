import { Box, FormControl } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { SnackbarContext } from '../../providers/SnackbarProvider';
import { Modal, ModalRef } from '../modal/modal';
import { getFormTemplate, handleTemplateSubmit } from './form.business';
import { UseFormModel, FormTemplate } from './form.constants';
import { useFormStyles } from './form.styles';

interface Props {
  modalForm?: boolean;
  containerClassName?: string;
  template: FormTemplate;
  toggleModal?: () => void;
  modalRef?: React.Ref<ModalRef>;
  optionalArgs?: object;
}

export const Form: React.VFC<Props> = (props) => {
  const Container = React.useMemo(() => (props.modalForm ? Modal : Box), [props.modalForm]);
  const FormTemplate = React.useMemo(() => getFormTemplate(props.template), [props.template]);

  const form = useForm({ defaultValues: FormTemplate.defaultValues });
  const { useSnackbar } = React.useContext(SnackbarContext);
  const [resetForm, setResetForm] = React.useState({});
  const styles = useFormStyles();

  React.useEffect(() => {
    form.reset({ ...FormTemplate.defaultValues });
  }, [resetForm]);

  const onSubmit = async (data: unknown): Promise<void> => {
    try {
      await handleTemplateSubmit(props.template, data, props.optionalArgs);
      setResetForm({});
      useSnackbar('El formulario ha sido enviado con éxito');
    } catch (error) {
      useSnackbar('El formulario no ha podido ser enviado', 'error');
    }
  };

  return (
    <Container className={props.containerClassName} ref={props.modalRef}>
      <FormControl
        className={!props.modalForm ? styles.modalContainer : styles.container}
        component="form"
        variant="standard"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormTemplate.component modalForm={props.modalForm} form={form as UseFormModel} onSubmit={onSubmit} {...props.optionalArgs} />
      </FormControl>
    </Container>
  );
};

Form.defaultProps = {
  optionalArgs: {}
};
