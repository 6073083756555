import { makeStyles } from '@material-ui/core';


export const useSwipperNavigationStyles = makeStyles((theme) => ({
    bullet: (inverse) => ({
    width: '15px',
    height: '15px',
    display: 'inline-block',
    borderRadius: '50%',
    borderColor: inverse ? theme.palette.secondary.main : theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:not(:last-of-type)': {
      marginRight: 10
    }
  }),
  activeBullet: (inverse) => ({
    background: inverse ? theme.palette.secondary.main : theme.palette.common.white
  }),
  navigation: (inverse) => ({
    userSelect: 'none',
    cursor: 'pointer',
    fontSize: 20,
    color: inverse ? theme.palette.secondary.main : theme.palette.common.white
  }),
  disabledNavigation: {
    cursor: 'default',
    opacity: 0.2
  },
}));
