import Button, { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { useButtonComponentStyles } from './ButtonComponent.style';

export interface ButtonComponentProps extends ButtonProps<React.ElementType, StylesProps> {
  isColorWhite?: boolean;
}

export const ButtonComponent: React.VFC<ButtonComponentProps> = ({ isColorWhite, ...props }) => {
  const classes = useButtonComponentStyles(props);

  return (
    <Button
      {...props}
      className={clsx(classes.btn, props.className, {
        [classes.colorWhite]: isColorWhite
      })}
    >
      {props.children}
    </Button>
  );
};
