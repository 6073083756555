import { makeStyles } from '@material-ui/core/styles';
import { TYPOGRAPHY_STYLES } from '../../styles/globals';

const useCookiesStyles = makeStyles((theme) => ({
  cookiesContainer: {
    fontFamily: theme.typography.fontFamily,
    padding: '10px 48px',
    '& > div:first-child': {
      flex: 'initial!important'
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%'
    },

    '& h5': {
      color: theme.palette.primary.main,
      fontSize: 18,
      FontWeight: 'bold'
    },
    '& p': {
      marginBottom: '0px'
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontSize: '14px'
    }
  },
  acceptButton: {
    fontFamily: TYPOGRAPHY_STYLES.fontFamily,
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.common.white}!important`,
    borderRadius: '30px!important',
    padding: '15px!important',
    fontWeight: 500
  },
  declineButton: {
    fontFamily: TYPOGRAPHY_STYLES.fontFamily,
    backgroundColor: `${theme.palette.common.white}!important`,
    color: `${theme.palette.primary.main}!important`,
    border: `solid 1px ${theme.palette.primary.main}!important`,
    borderRadius: '30px!important',
    padding: '15px!important',
    fontWeight: 500
  },
  cookieTitle: {
    fontWeight: 600
  },
  cookieContent: {
    paddingRight: '2rem'
  }
}));

export default useCookiesStyles;

export const generalStyle = {
  backgroundColor: 'white',
  alignItems: 'baseline',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  left: '0',
  position: 'fixed',
  width: '100%',
  zIndex: 999
};
