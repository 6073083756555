import { makeStyles } from '@material-ui/core/styles';

export const useIconStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 30,
    height: 30
  },
  label: {
    marginLeft: 10
  }
}));
