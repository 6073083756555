import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ReactPlayer from 'react-player';
import { getDamImage } from '../../helpers/AppHelpers';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { useVideoCardStyles } from './VideoCard.styles';

interface Props {
  text: string;
  video: string;
  thumbnail?: string;
  metadata: MagnoliaMetadata;
}

export const VideoCard: React.VFC<Props> = (props) => {
  const styles = useVideoCardStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className={styles.container}>
      <div className={styles.textContainer} dangerouslySetInnerHTML={{ __html: props.text }} />
      <div className={styles.videoPlayer}>
        <ReactPlayer
          width={!isDesktop ? '100%' : undefined}
          height={!isDesktop ? '100%' : undefined}
          url={getDamImage(props.video)}
          controls
        />
      </div>
    </div>
  );
};
