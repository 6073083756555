import { EditableArea } from '@magnolia/react-editor';
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { ButtonList } from '../button_list/ButtonList';
import { Form } from '../forms/form';
import { FormTemplate } from '../forms/form.constants';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import { usePresentationContentStyles } from './PresentationContent.styles';

interface Props extends StylesProps {
  descriptions: string;
  image: MagnoliaImage;
  buttons: MagnoliaJcrMultiField;
  id?: string;
  content?: object;
  footerTitle?: string;
  showCVForm?: string;
  metadata: object;
  title?: string;
}

export const PresentationContent: React.VFC<Props> = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const styles = usePresentationContentStyles();
  const parentTemplateId = props.metadata['mgnl:template'];

  return (
    <div>
      <Container>
        <Box className={styles.root} id={props.id}>
          <div className={styles.description}>
            <RichText text={props.descriptions} />
            {!!props.buttons['@nodes'].length && <ButtonList button={props.buttons} />}
          </div>
          <div className={styles.imageContainer}>
            <Image image={props.image} />
            {JSON.parse(props.showCVForm) && (
              <Form
                containerClassName={styles.curriculumForm}
                optionalArgs={{ offerId: props.metadata['@id'], offerTitle: props.title }}
                template={FormTemplate.CV}
              />
            )}
          </div>
        </Box>
        <Box>{props.content && <EditableArea content={props.content} parentTemplateId={parentTemplateId} />}</Box>
        {isDesktop && props.footerTitle && <div className={styles.title}>{props.footerTitle}</div>}
      </Container>
    </div>
  );
};

PresentationContent.defaultProps = {
  descriptions: ''
};
