import { Box, Container, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { Modal, ModalRef } from '../modal/modal';
import { FilterColumnComponent } from './components/FilterColumn';
import { FilterColumn } from './constants';
import { getInitialFilterState } from './Filter.business';
import { useFilterStyles } from './Filter.styles';

interface Props {
  modalRef: React.Ref<ModalRef>;
  columns: FilterColumn[];
  onChangeFilters: (filters: Record<string, string[]>) => void;
  title?: string;
}

export const Filter: React.VFC<Props> = (props) => {
  const styles = useFilterStyles();
  const [activeFilters, setActiveFilter] = React.useState<Record<string, string[]>>(getInitialFilterState(props.columns));

  const addFilter = (columnName: string, filter: string): void => {
    const filters = { ...activeFilters, [columnName]: [...activeFilters[columnName], filter] };
    setActiveFilter(filters);
    props.onChangeFilters(filters);
  };

  const removeFilter = (columnName: string, filter: string): void => {
    const filters = { ...activeFilters, [columnName]: activeFilters[columnName].filter((activeFilter) => activeFilter !== filter) };
    setActiveFilter(filters);
    props.onChangeFilters(filters);
  };

  return (
    <Modal ref={props.modalRef}>
      <Container className={styles.container}>
        <Box>
          <Typography>Filtrar</Typography>
          <Typography variant="h5" className={styles.offersLabel}>
            {props.title}
          </Typography>
          <Divider className={styles.divider} />
        </Box>
        <Box className={styles.columnContainer}>
          {props.columns.map((column, index) => (
            <FilterColumnComponent
              key={index}
              activeFilters={activeFilters[column.title]}
              addFilter={addFilter}
              removeFilter={removeFilter}
              column={column}
              divider={index + 1 !== props.columns.length}
            />
          ))}
        </Box>
      </Container>
    </Modal>
  );
};
