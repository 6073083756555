import React from 'react';
import useHamburgerButtomtyles from './HamburgerButtom.style';

interface HamburgerButtomProps {
  isOpen: boolean;
  handleClick: any;
}

const HamburgerButtom: React.VFC<HamburgerButtomProps> = ({ isOpen, handleClick }) => {
  const classes = useHamburgerButtomtyles({ isOpen });

  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.topHamburger}></div>
      <div className={classes.mediumHamburger}></div>
      <div className={classes.bottomHamburger}></div>
    </div>
  );
};

export default HamburgerButtom;
