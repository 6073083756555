import { AppTheme } from '../types/theme';
import { COLORS } from '../variables/colors';

export const defaultThemeConfig: AppTheme = {
  palette: {
    primary: { main: COLORS.main, light: COLORS.light, dark: COLORS.dark },
    secondary: { main: COLORS.mainSecondary, light: COLORS.lightSecondary, dark: COLORS.darkSecondary },
    background: {
      default: 'white'
    }
  },
  backgroundColor: 'white'
};
