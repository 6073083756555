import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import useCookiesStyles, { generalStyle } from './Cookies.style';

const CookieConsentComponent = () => {
  const cookieClasses = useCookiesStyles();

  return (
    <CookieConsent
      overlay
      containerClasses={cookieClasses.cookiesContainer}
      buttonClasses={cookieClasses.acceptButton}
      declineButtonClasses={cookieClasses.declineButton}
      buttonText="Permitir todas las cookies"
      setDeclineCookie={false}
      enableDeclineButton={true}
      declineButtonText="Cerrar"
      style={generalStyle}
    >
      <Typography className={cookieClasses.cookieTitle} variant="h5" color="primary">
        ESTA PÁGINA WEB USA COOKIES
      </Typography>
      <Box mt={2}>
        <Typography className={cookieClasses.cookieContent} variant="body2">
          Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y
          analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con nuestros partners de redes sociales,
          publicidad y análisis web, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a
          partir del uso que haya hecho de sus servicios.
        </Typography>
      </Box>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
