import { EditableArea } from '@magnolia/react-editor';
import { Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Form } from '../../components/forms/form';
import { FormTemplate } from '../../components/forms/form.constants';
import { Image } from '../../components/image/Image';
import { ModalRef } from '../../components/modal/modal';
import { TechnologyCarousel } from '../../components/technologyCarousel/technologyCarousel';
import { Layout } from '../../layout/Layout';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { useTechnologyStyles } from './Technology.styles';

interface Props {
  header?: object;
  footer?: object;
  name: string;
  title: string;
  subtitle: string;
  image?: MagnoliaImage;
  description: string;
  metadata?: MagnoliaMetadata;
  logo?: MagnoliaImage;
  content?: MagnoliaJcrMultiField;
}

export const Technology: React.VFC<Props> = (props) => {
  const styles = useTechnologyStyles();
  const modalRef = React.useRef<ModalRef>();
  const onClickContact = () => modalRef.current?.toggleModal();

  return (
    <Layout
      latestNewsChildren={<TechnologyCarousel currentTechnology={props.name} showDownloadButton={true} title="Otras" subtitle="TECNOLOGÍAS"/>}
    >
      <Container>
          <Box className={styles.presentationContainer}>
          <Box className={styles.titleContainer}>
            <div dangerouslySetInnerHTML={{ __html: props.title }} />
            {props.content && <EditableArea content={props.content} />}
          </Box>
          <Box className={styles.imageContainer}>{props.image && <Image image={props.image} />}</Box>
        </Box>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }} />
        <Box className={styles.contactContainer}>
          <Typography variant="subtitle1">Contacta con un experto en esta</Typography>
          <Typography variant="h5" className={styles.contactSubtitle}>
            TECNOLOGÍA
          </Typography>
          <Button className={styles.contactButton} onClick={onClickContact}>
            Contactar ahora
          </Button>
        </Box>
        <Form template={FormTemplate.Contact} modalForm={true} modalRef={modalRef} />
      </Container>
      <Typography variant="h1" className={styles.sectionTitle}>
        TECNOLOGÍAS
      </Typography>
    </Layout>
  );
};
