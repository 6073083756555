import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { StylesProps } from '../../models/StylesProps';
import { Image } from '../image/Image';
import RichText from '../rich_text/RichText';
import { useCardLabelStyles } from './CardLabel.style';

type CardDirection = 'toRight' | 'toLeft';

export interface CardLabelProps extends StylesProps {
  text?: string;
  imageAttached?: MagnoliaImage;
  cardDirection: CardDirection;
  cardSize?: string;
  cardLength?: string;
}

export const CardLabel: React.VFC<CardLabelProps> = (props) => {
  const classes = useCardLabelStyles(props);

  return (
    <Box className={classes.root} card-direction={props.cardDirection}>
      <Container className={clsx(classes.root, { [classes.removeContainer]: !JSON.parse(props.containerCheckbox) })}>
        <div className={classes.content}>
          <Box className={classes.cardBody} card-direction={props.cardDirection} card-size={props.cardSize} card-length={props.cardLength}>
            {props.cardDirection === 'toRight' && (
              <>
                <Box className={classes.cardRichtext} card-size={props.cardSize} card-length={props.cardLength}>
                  <RichText text={props.text ?? ''} />
                </Box>
                <Box className={classes.cardImage}>{props.imageAttached && <Image image={props.imageAttached} />}</Box>
              </>
            )}

            {props.cardDirection === 'toLeft' && (
              <>
                <Box className={classes.cardImage}>{props.imageAttached && <Image image={props.imageAttached} />}</Box>
                <Box className={classes.cardRichtext} card-size={props.cardSize} card-length={props.cardLength}>
                  <RichText text={props.text ?? ''} />
                </Box>
              </>
            )}
          </Box>
        </div>
      </Container>
    </Box>
  );
};

CardLabel.defaultProps = {
  containerCheckbox: 'true'
};
