import { Box, Button, Container, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowDownwardRounded } from '@material-ui/icons';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MagnoliaEndpoints } from '../../constants';
import { getAPIPage } from '../../helpers/AppHelpers';
import { Technology } from '../../models/Technology';
import { get } from '../../services/http.service';
import { useSwipperNavigationStyles } from '../../styles/common/swiper-navigation';
import { Image } from '../image/Image';
import { fillTechnologiesSlide } from './technologyCarousel.business';
import { useTechnologyCarouselStyles } from './technologyCarousel.styles';

interface Props {
  currentTechnology?: string;
  showDownloadButton?: boolean;
  title?: string;
  subtitle?: string;
}

export const TechnologyCarousel: React.VFC<Props> = (props) => {
  const styles = useTechnologyCarouselStyles();
  const swipperStyles = useSwipperNavigationStyles(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [technologies, setTechnologies] = React.useState<Technology[]>([]);

  React.useEffect(() => {
    loadTechnologies();
  }, []);

  const loadTechnologies = async () => {
    try {
      const result = await get<Technology[]>(getAPIPage(MagnoliaEndpoints.TECHNOLOGY, true), { baseURL: '' });
      const filterTechnologies = result.data?.filter((data) => data.name !== props.currentTechnology) ?? [];
      setTechnologies(fillTechnologiesSlide(filterTechnologies, isDesktop));
    } catch (error) {
      console.error(error);
    }
  };

  const filledTechnologies: Technology[] = React.useMemo(() => fillTechnologiesSlide(technologies, isDesktop), [technologies, isDesktop]);

  return (
    <Container>
      {(props.title || props.subtitle) && (
          <Box className={styles.headerContainer}>
            <Typography variant="subtitle1">{props.title}</Typography>
            <Typography variant="h5" className={styles.headerSubtitle}>
              {props.subtitle}
            </Typography>
          </Box>
        )}
      <Box className={styles.swiperContainer}>
        <div id="navigation-prev" className={swipperStyles.navigation}>
          &#9664;
        </div>
        <Swiper
          navigation={{
            prevEl: '#navigation-prev',
            nextEl: '#navigation-next',
            disabledClass: swipperStyles.disabledNavigation
          }}
          pagination={{
            clickable: true,
            el: '#pagination',
            bulletClass: swipperStyles.bullet,
            bulletActiveClass: swipperStyles.activeBullet
          }}
          slidesPerView={isDesktop ? 3 : 1}
          slidesPerColumnFill={'row'}
          slidesPerColumn={1}
          slidesPerGroup={isDesktop ? 3 : 1}
          role="grid"
          direction="horizontal"
          className={styles.swiper}
          loopFillGroupWithBlank={true}
        >
          {filledTechnologies.map((technology, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              {technology && (
                <Box className={styles.technologyContainer}>
                  <Image image={technology?.logo} wrapperClassName={styles.image} />
                  <Typography className={styles.description}>{technology?.shortDescription}</Typography>
                  {props.showDownloadButton && (
                    <IconButton className={styles.downloadButton}>
                      <ArrowDownwardRounded className={styles.downloadIcon} />
                    </IconButton>
                  )}
                  <Button href={technology['@path']} className={styles.linkButton}>
                    Ver tecnología
                  </Button>
                </Box>
              )}
              {index + 1 !== technologies.length && (index + 1) % 3 !== 0 && isDesktop && <Divider className={styles.divider} />}
            </SwiperSlide>
          ))}
        </Swiper>
        <div id="navigation-next" className={swipperStyles.navigation}>
          &#9654;
        </div>
      </Box>
      <div className={styles.pagination} id="pagination" />
    </Container>
  );
};
