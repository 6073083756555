import { makeStyles } from '@material-ui/core/styles';

export const useTechnologyStyles = makeStyles((theme) => ({
  presentationContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'default',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  headerContainer:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.common.white
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignSelf: 'center',
    },
    '& img': {
      width: '100%'
    }
  },
  contactContainer: {
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 50,
  },
  description: {
    cursor: 'default',
    marginTop: 40,
  },
  technology: {
    display: 'flex',
    flexDirection: 'column'
  },
  contactSubtitle: {
    fontWeight: 'bold',
    letterSpacing: 5,
    textTransform: 'uppercase'
  },
  contactButton: {
    marginTop: 15,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: '5px 30px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  sectionTitle: {
    cursor: 'default',
    color: '#f4f4f4',
    fontWeight: 'bold',
    letterSpacing: 15,
    fontSize: '7rem',
    textTransform: 'uppercase',
    marginBottom: '-25px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));
