import { UseFormReturn } from 'react-hook-form';

export const salarySelectValues = [
  '12.000 - 20.000',
  '20.000 - 30.000',
  '30.000 - 40.000',
  '40.000 - 50.000',
  '50.000 - 60.000',
  'Más de 60.000'
];

export const experienceSelectValues = ['0 - 1', '1 - 2', '2 - 4', 'Más de 5'];

export const roleSelectValues = [
  'Auditoría',
  'Cadena de suministros',
  'Compras',
  'Desarrollo de productos (i+d)',
  'Director general',
  'Excelencia de procesos',
  'Finanzas',
  'Gestión de proyectos',
  'IT/Sistemas',
  'Legal',
  'Logística',
  'Operaciones',
  'Otros',
  'RRHH',
  'Servicios al cliente',
  'Transformación digital',
  'Ventas'
];

export const levelSelectValues = [
  'Analista',
  'CEO/Presidente',
  'Director',
  'General manager',
  'Gerente',
  'Jefe/Responsable área',
  'Manager',
  'Otros directivos (C-Level)',
  'Partner',
  'Supervisor',
  'Técnico',
  'Vicepresidente',
];

export interface CurriculumFormModel {
  offerId: string;
  offerTitle: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  introduction: string;
  experienceYears: string;
  salaryBand: string;
  cv: File;
  gdprAccept: boolean;
}

export const curriculumFormDefaultValues: CurriculumFormModel = {
  offerId: '',
  offerTitle: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  introduction: '',
  experienceYears: '',
  salaryBand: '',
  cv: null,
  gdprAccept: false
};

export interface ContactFormModel {
  name: string;
  surname: string;
  email: string;
  phone: string;
  message: string;
  gdprAccept?: boolean;
}

export const contactFormDefaultValues: ContactFormModel = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  message: '',
  gdprAccept: false
};

export interface DownloadFormModel {
  name: string;
  surname: string;
  email: string;
  company: string;
  country: string;
  role: string;
  level: string;
  gdprAccept?: boolean;
  fileName?: string;
}

export const downloadFormDefaultValues: DownloadFormModel = {
  name: '',
  surname: '',
  email: '',
  company: '',
  country: '',
  level: '',
  role: '',
  gdprAccept: false
};

export type UseFormModel = UseFormReturn<CurriculumFormModel, object> & UseFormReturn<ContactFormModel, object> & UseFormReturn<DownloadFormModel, object>;

export enum FormTemplate {
  CV = 'cv',
  Contact = 'contact',
  Download = 'download'
}
