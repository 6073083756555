import { EditableArea } from '@magnolia/react-editor';
import { Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Form } from '../../components/forms/form';
import { FormTemplate } from '../../components/forms/form.constants';
import { ModalRef } from '../../components/modal/modal';
import { ProjectCarousel } from '../../components/projectCarousel/ProjectCarousel';
import { Layout } from '../../layout/Layout';
import { useProjectStyles } from './Project.styles';


interface Props {
  main?: object;
  name: string;
}

export const Project: React.VFC<Props> = (props) => {
  const styles = useProjectStyles();
  const modalRef = React.useRef<ModalRef>();
  const onClickContact = () => modalRef.current?.toggleModal();

  return (
    <Layout>
      {props.main && <EditableArea content={props.main} />}
      <ProjectCarousel currentProject={props.name} showTitle={true} showFilter={false} headerSubtitle="RELACIONADOS" labelSection="OTROS PROYECTOS" />
      <Box className={styles.contactBackground}>
        <Container className={styles.contactContainer}>
          <Typography variant="h6" className={styles.contactTitle}>¿Tienes</Typography>
          <Typography variant="h5" className={styles.contactSubtitle}>ALGUNA DUDA?</Typography>
          <Button onClick={onClickContact} className={styles.contactButton}>
            Contacta con un experto
          </Button>
          <Form template={FormTemplate.Contact} modalForm={true} modalRef={modalRef} />
        </Container>
      </Box>
    </Layout>
  );
};
