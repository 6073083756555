import { JobOffer } from '../../models/JobOffer';
import { experienceColumn, rolColumn, technologyColumn } from './constants';

export const filterOffers = (offers: JobOffer[], filters: Record<string, string[]>) => {
  if (!filters) {
    return fillArray(offers);
  }

  const tecnology = filters[technologyColumn.title];
  const rol = filters[rolColumn.title];
  const experience = filters[experienceColumn.title];

  const filteredOffers = offers.reduce((acc, offer) => {
    const includeTechnology = !!tecnology?.length ? tecnology.includes(offer.technology) : true;
    const includeRol = !!rol?.length ? rol.includes(offer.rol) : true;
    const includeExperience = !!experience?.length ? experience.includes(offer.experience) : true;

    if (includeTechnology && includeRol && includeExperience) {
      return [...acc, offer];
    }
    return acc;
  }, []);

  return fillArray(filteredOffers);
};

const fillArray = (offers: JobOffer[]) => {
  const nextMultiple = Math.ceil(offers.length / 9) * 9 - offers.length;
  return offers.length > 9 ? [...offers, ...Array(nextMultiple).fill(null)] : offers;
};
