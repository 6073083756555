import { Box, Divider, FormControlLabel, Switch, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { FilterColumn } from '../constants';
import { useFilterColumnStyles } from './FilterColumn.styles';

interface Props {
  column: FilterColumn;
  activeFilters: string[];
  removeFilter: (columnName: string, filter: string) => void;
  addFilter: (columnName: string, filter: string) => void;
  divider?: boolean;
}

export const FilterColumnComponent: React.VFC<Props> = (props) => {
  const styles = useFilterColumnStyles();

  const onChange = (filter: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    checked ? props.addFilter(props.column.title, filter) : props.removeFilter(props.column.title, filter);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.columnContainer}>
        <Typography className={styles.title}>{props.column.title}</Typography>
        <Box className={styles.filterContainer}>
          {props.column.filters.map((filter, index) => (
            <FormControlLabel
              key={index}
              className={clsx(styles.formControl, { [styles.formControlActive]: props.activeFilters.includes(filter) })}
              control={
                <Switch
                  onChange={onChange(filter)}
                  classes={{
                    root: styles.root,
                    switchBase: styles.switchBase,
                    checked: styles.checked,
                    track: styles.track,
                    thumb: styles.thumb
                  }}
                  checked={props.activeFilters.includes(filter)}
                />
              }
              classes={{ label: styles.filterLabel, root: styles.formControlRoot }}
              label={filter}
            />
          ))}
        </Box>
      </Box>
      {props.divider && <Divider className={styles.divider} />}
    </Box>
  );
};
