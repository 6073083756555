import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { responsiveContainerMargins } from '../../styles/globals';

const useImagesCarousel = makeStyles<Theme, StylesProps>((theme) => ({
  root: (props: StylesProps) => ({
    ...responsiveContainerMargins(theme, props)
  })
}));

export default useImagesCarousel;
