import { makeStyles } from '@material-ui/core/styles';

const useFooterStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 0',
    color: '#999999',
    '& a': {
      color: ' #999999 !important'
    },
    '& .first-row': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 20
      },
      '& .logo-column': {
        '& img': {
          width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 15
        }
      },
      '& .text-column': {
        marginTop: -15,
        display: 'flex',
        gap: 100,
        [theme.breakpoints.down('xs')]: {
          padding: 10,
          gap: 0,
          flexDirection: 'column'
        }
      },
      '& .social-column': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .icons-list': {
          display: 'flex',
          [theme.breakpoints.down('xs')]: {
            gap: 10,
            justifyContent: 'space-around'
          },
          '& a': {
            textDecoration: 'none',
            margin: '0 3px',
            width: '25px',
            '& img': {
              width: '100%',
              [theme.breakpoints.down('xs')]: {
                width: '120%'
              }
            }
          }
        },
        '& .seals-images': {
          marginTop: 15,
          display: 'flex',
          width: '150px',
          '& span': {
            padding: 6,
            '& img': {
              width: '100%'
            }
          }
        }
      }
    },
    '& .second-row': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start'
      },
      '& .copyright': {
        fontSize: '0.65rem',
        [theme.breakpoints.down('xs')]: {
          order: 2,
          width: '40%',
          textAlign: 'end'
        }
      },
      '& .link-area': {
        '& ul': {
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            paddingLeft: 0,
            alignItems: 'flex-start',
            position: 'relative',
            left: -18,
            top: -1
          },
          '& .legal-item': {
            display: 'flex',
            alignItems: 'center',
            '& .dot': {
              height: 4,
              width: 4,
              backgroundColor: '#999999',
              borderRadius: '50%',
              [theme.breakpoints.down('xs')]: {
                display: 'none'
              }
            },
            '& a': {
              color: 'inherit',
              textDecoration: 'none',
              margin: '0 20px',
              fontSize: '0.65rem',
              '&:visited': {
                color: 'inherit'
              }
            }
          },
          '& .legal-item:first-child .dot': {
            display: 'none'
          }
        }
      }
    },
    '& hr': {
      color: 'inherit'
    }
  }
}));

export default useFooterStyles;
