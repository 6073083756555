import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import Slide, { SlideProps } from '../slide/Slide';
import { GenerateCustomerOpinions } from './components/GenerateCustomerOpinions';
import { useCustomerOpinionsContainerStyles } from './CustomerOpinions.styles';

interface CustomerOpinionProps extends StylesProps, SlideProps {
  slides: MagnoliaJcrMultiField;
  id?: string;
}

export const CustomerOpinions: React.VFC<CustomerOpinionProps> = (props) => {
  const classes = useCustomerOpinionsContainerStyles({ ...props });
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const slideProps = {
    desktopSlides: props.desktopSlides,
    tabletSlides: props.tabletSlides,
    mobileSlides: props.mobileSlides,
    spaceBetween: props.spaceBetween,
    navigation: props.navigation,
    pagination: props.pagination,
    loop: props.loop,
    autoplay: props.autoplay,
    delay: props.delay,
    desktopRows: props.desktopRows,
    tabletRows: props.tabletRows,
    mobileRows: props.mobileRows,
    nodes: GenerateCustomerOpinions(props)
  };

  const [navigateSlide, setNavigateSlide] = useState<Partial<SlideProps>>({
    navigation: props.navigation,
    pagination: props.pagination
  });

  useEffect(() => {
    setNavigateSlide(isXs ? { navigation: 'false', pagination: 'true' } : { navigation: props.navigation, pagination: props.pagination });
  }, [isXs]);

  return (
    <Box className={classes.root} id={props.id}>
      <Container>
        <Slide {...slideProps} {...navigateSlide} />
      </Container>
    </Box>
  );
};

CustomerOpinions.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
