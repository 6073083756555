import { makeStyles } from '@material-ui/core/styles';
import { getMagnoliaUrlImage } from '../../helpers/AppHelpers';
import { ImageType } from '../../models/ImageType';
import { HEADER } from './../../styles/variables/sizes';

interface StyleProps {
  isOpen: boolean;
  imageBackground?: ImageType;
}

const useHeaderStyles = makeStyles((theme) => ({
  root: ({ imageBackground }: StyleProps) => ({
    position: 'relative',
    background: 'gray',
    backgroundImage: getMagnoliaUrlImage(imageBackground),
    backgroundSize: 'cover',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.45)'
    }
  }),
  toolbarContainer: {
    background: 'transparent',
    boxShadow: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey.A100
  },
  header: {
    display: 'flex',
    position: 'relative',
    height: HEADER.height,
    [theme.breakpoints.down('xs')]: {
      height: HEADER.mobileHeight
    }
  },
  [theme.breakpoints.down('xs')]: {
    imageContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& img': {
        width: '50%',
        padding: '% 0'
      }
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  linkArea: ({ isOpen }: StyleProps) => ({
    listStyle: 'none',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    width: '60%',
    height: '100%',
    marginTop: 0,
    top: HEADER.mobileHeight,
    '& a': {
      color: 'white',
      display: 'flex',
      textDecoration: 'none',
      padding: 25
    },
    transform: isOpen ? 'translateX(150%)' : 'translateX(-93%)',
    transition: 'transform ease 0.5s',
    background: '#5d5a7ee0',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      flexDirection: 'row',
      position: 'relative',
      transform: 'inherit',
      width: '100%',
      background: 'none',
      height: 'auto',
      marginTop: 'initial',
      top: 'initial'
    }
  })
}));

export default useHeaderStyles;
