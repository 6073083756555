import { EditablePage, EditorContextHelper } from '@magnolia/react-editor';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import CookieConsentComponent from '../components/cookie_consent/CookieConsent';
import Loading from '../components/loading/Loading';
import { MGNL_API_PAGES, MGNL_API_TEMPLATES, MGNL_APP_BASE } from '../constants';
import { config } from '../magnolia.config';
import { SeoModel } from '../models/SeoModel';
import { SEO_BASE_CONFIG } from '../seo.config';
import { getAPIBase, getCurrentLanguage, removeCurrentLanguage, removeLastSlash } from './AppHelpers';
interface PageLoaderState {
  init: boolean;
  pathname: string;
  content: any;
  templateDefinitions: any;
}

const PageLoader = () => {
  const [state, setState] = useState<PageLoaderState>();
  const [seoConfig, setSeoConfig] = useState<SeoModel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadPage().then(() => {
      setLoading(false);
    });
  }, [window.location.pathname]);

  const getPagePath = () => {
    const nodeName = MGNL_APP_BASE;
    const currentLanguage = getCurrentLanguage();

    let path = nodeName + window.location.pathname.replace(new RegExp(`(.*${nodeName}|.html)`, 'g'), '');

    return `${removeCurrentLanguage(path)}?lang=${currentLanguage}`;
  };

  const loadPage = async () => {
    // Bail out if already loaded content.
    if (state && removeLastSlash(state.pathname) === removeLastSlash(window.location.pathname)) {
      return;
    }

    const apiBase = getAPIBase();
    const pagePath = getPagePath();
    let fullContentPath = apiBase + MGNL_API_PAGES + pagePath;

    const pageResponse = await fetch(fullContentPath);
    const pageJson = await pageResponse.json();

    const templateId = pageJson['mgnl:template'];

    let templateJson = null;
    if (EditorContextHelper.inEditor()) {
      const templateResponse = await fetch(apiBase + MGNL_API_TEMPLATES + '/' + templateId);
      templateJson = await templateResponse.json();
    }

    setState({
      init: true,
      content: pageJson,
      templateDefinitions: templateJson,
      pathname: window.location.pathname
    });
    const seoState = {
      seoTitle: pageJson.seoTitle,
      seoDescription: pageJson.seoDescription,
      seoKeywords: pageJson.seoKeywords,
      seoCanonical: pageJson.seoCanonical,
      seoRobots: pageJson.seoRobots
    };
    setSeoConfig({
      ...SEO_BASE_CONFIG,
      ...seoState
    });
  };

  const seoTititle = () => `${seoConfig.seoTitle || ''}${seoConfig.seoTitle ? ' | ' : ''}${seoConfig.seoBaseTitle}`;
  return state?.init ? (
    <>
      {seoConfig && (
        <Helmet>
          {<title>{seoTititle()}</title>}
          {<meta property="og:title" content={seoTititle()} />}
          {seoConfig.seoDescription && <meta name="description" content={seoConfig.seoDescription} />}
          {seoConfig.seoDescription && <meta property="og:description" content={seoConfig.seoDescription} />}
          {state.content.seoKeywords && <meta name="keywords" content={state.content.seoKeywords} />}
          {state.content.seoCanonical && <link rel="canonical" href={state.content.seoCanonical} />}
          {state.content.seoRobots && <meta name="robots" content={state.content.seoRobots} />}
        </Helmet>
      )}
      {loading && <Loading />}
      <EditablePage templateDefinitions={state.templateDefinitions} content={state.content} config={config} />
      <CookieConsentComponent />
    </>
  ) : (
    <Loading />
  );
};

export default PageLoader;
