import { Button, FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, ControllerRenderProps, FieldError, UseControllerProps } from 'react-hook-form';
import { useControlledFileInputStyles } from './ControlledFileInput.styles';

type TextFieldProps =
  | Omit<StandardTextFieldProps, 'onChange' | 'value'>
  | Omit<FilledTextFieldProps, 'onChange' | 'value'>
  | Omit<OutlinedTextFieldProps, 'onChange' | 'value'>;

interface Props<T>  {
  controlProps: UseControllerProps<T> ;
  textFieldProps: TextFieldProps;
  error?: FieldError;
}

export const ControlledFileInput = <T,>(props: Props<T> ) => {
  const styles = useControlledFileInputStyles();
  const inputFile = React.useRef<HTMLInputElement>(null);

  const onClickFileButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    inputFile.current?.click();
  };

  const onChangeFile =
    (onChange: ControllerRenderProps['onChange']) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      onChange(event.target.files[0]);
      inputFile.current.value = null;
    };

  return (
    <Controller
      {...props.controlProps}
      render={({ field: { onChange, value} }) => (
        <>
          <input type="file" onChange={onChangeFile(onChange)} hidden={true} ref={inputFile} accept="application/pdf" />
          <TextField
            {...props.textFieldProps}
            error={!!props.error}
            helperText={props.error?.message}
            value={(value as File)?.name ?? ''}
            contentEditable={false}
            InputProps={{
              inputProps: { className: styles.fileInput },
              endAdornment: (
                <Button className={styles.fileButton} variant="outlined" onClick={onClickFileButton}>
                  Examinar
                </Button>
              )
            }}
          />
        </>
      )}
    />
  );
};
