import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { CardStylesProps } from '../../models/CardStylesProps';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import { showRightDivider } from '../../utils/SwiperUtils';
import { CardItem } from '../cards/CardItem';
import Slide, { SlideProps } from '../slide/Slide';
import { useCardListStyles } from './CardList.styles';

interface CardListProps extends StylesProps, SlideProps, CardStylesProps {
  image: MagnoliaImage;
  buttons: MagnoliaJcrMultiField;
  text: string;
  page: string;
  informations: any;
  date: string;
  link: string;
  icon: MagnoliaImage;
  slides: MagnoliaJcrMultiField;
  id?: string;
  switchable: any;
  hasDivider: string;
}

export const CardList: React.VFC<CardListProps> = (props) => {
  const classes = useCardListStyles({ ...props });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const numberOfSlides = JSON.parse(props.desktopSlides);
  const hasDivider = props.hasDivider === undefined ? false : JSON.parse(props.hasDivider);

  return (
    <Box id={props.id}>
      <Container className={classes.root}>
        <Slide
          {...props}
          nodes={props.slides['@nodes'].map((name, index) => {
            const cardListItem = props.slides[name];
            return (
              <CardItem
                {...cardListItem}
                {...props}
                key={name}
                image={cardListItem.image}
                buttons={cardListItem.buttons}
                page={cardListItem.page}
                informations={cardListItem.informations}
                date={cardListItem.switchable.dataField}
                link={cardListItem.switchable.linkField}
                icon={cardListItem.switchable.imageIcon}
                hasDivider={hasDivider}
                rightDivider={showRightDivider(isDesktop, index + 1, numberOfSlides)}
              />
            );
          })}
        />
      </Container>
    </Box>
  );
};
