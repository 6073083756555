import { omit } from 'lodash';
import { Endpoints } from '../../constants';
import { post } from '../../services/http.service';
import { ContactFormModel, CurriculumFormModel, DownloadFormModel } from './form.constants';

export const sendCV = async (data: CurriculumFormModel): Promise<void> => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  await post(Endpoints.JOB, formData, {
    url: Endpoints.JOB,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const sendContact = async (data: ContactFormModel): Promise<void> => {
  await post(Endpoints.CONTACT, data, { url: Endpoints.CONTACT });
};

export const sendDownloadInfo = async (data: DownloadFormModel): Promise<void> => {
  await post(Endpoints.DOWNLOAD, { ...omit(data, 'gdprAccept'), country: data.country || 'undefined' }, { url: Endpoints.DOWNLOAD });
};
