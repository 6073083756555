import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { escapeHtml } from '../../../helpers/AppHelpers';
import { ControlledCheckbox } from '../components/ControlledCheckbox/ControlledCheckbox';
import { ControlledFileInput } from '../components/ControlledFileInput/ControlledFileInput';
import { ControlledSelect } from '../components/ControlledSelect/ControlledSelect';
import { ControlledTextField } from '../components/ControlledTextField/ControlledTextField';
import { curriculumFormDefaultValues, CurriculumFormModel, experienceSelectValues, salarySelectValues } from '../form.constants';
import { useFormStyles } from '../form.styles';
import { emailRules } from './rules';

interface Props {
  modalForm?: boolean;
  offerId?: string;
  offerTitle?: string;
  containerClassName?: string;
  onSubmit: (data: CurriculumFormModel) => void;
  form: UseFormReturn<CurriculumFormModel, object>;
}

export const CurriculumForm: React.VFC<Props> = (props) => {
  const styles = useFormStyles();
  const title = React.useMemo(() => (props.modalForm ? 'ENVÍA TU CURRICULUM' : '¿ESTÁS INTERESADO?'), [props.modalForm]);
  const { offerId, offerTitle } = props;
  const subtitle = React.useMemo(
    () =>
      props.modalForm
        ? 'FACILÍTANOS TUS DATOS SI DESEAS TRABAJAR CON NOSOTROS EN INNOCV'
        : 'ENVÍANOS TU CURRÍCULUM SI DESEAS APLICAR A ESTA OFERTA',
    [props.modalForm]
  );

  const submitForm = (form) => {
    props.onSubmit({ ...form, offerId, offerTitle: escapeHtml(offerTitle) });
  };

  return (
    <>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.subtitle}>{subtitle}</Typography>
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu nombre', className: styles.input }}
        controlProps={{
          name: 'name',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.name
        }}
        error={props.form.formState.errors.name}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tus apellidos', className: styles.input }}
        controlProps={{
          name: 'surname',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.surname
        }}
        error={props.form.formState.errors.surname}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Introduce tu dirección de correo electrónico', className: styles.input, type: 'email' }}
        controlProps={{
          name: 'email',
          rules: emailRules,
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.email
        }}
        error={props.form.formState.errors.email}
      />
      <ControlledTextField
        textFieldProps={{ placeholder: 'Facilítanos un teléfono de contacto', className: styles.input, type: 'tel' }}
        controlProps={{
          name: 'phone',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.phone
        }}
        error={props.form.formState.errors.phone}
      />
      <ControlledTextField
        textFieldProps={{
          placeholder: 'Presentación (máx. 500 caracteres)',
          className: styles.textArea,
          minRows: 10,
          variant: 'outlined',
          multiline: true,
          inputProps: { maxLength: 500 }
        }}
        controlProps={{
          name: 'introduction',
          rules: { required: true, maxLength: 500 },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.introduction
        }}
        error={props.form.formState.errors.introduction}
      />
      <ControlledSelect
        values={experienceSelectValues}
        selectProps={{ placeholder: 'Años de experiencia', displayEmpty: true, className: styles.input }}
        controlProps={{
          name: 'experienceYears',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.experienceYears
        }}
        error={props.form.formState.errors.experienceYears}
      />
      <ControlledSelect
        values={salarySelectValues}
        selectProps={{ placeholder: 'Banda salarial', displayEmpty: true, className: styles.input }}
        controlProps={{
          name: 'salaryBand',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.salaryBand
        }}
        error={props.form.formState.errors.salaryBand}
      />
      <ControlledFileInput
        textFieldProps={{ placeholder: 'Adjunta tu CV (máx. 4 Mb)', className: styles.input }}
        controlProps={{ name: 'cv', rules: { required: true }, control: props.form.control, defaultValue: curriculumFormDefaultValues.cv }}
        error={props.form.formState.errors.cv}
      />
      <ControlledCheckbox
        labelProps={{ label: 'Acepto el manejo y almacenamiento de mis datos.' }}
        controlProps={{
          name: 'gdprAccept',
          rules: { required: true },
          control: props.form.control,
          defaultValue: curriculumFormDefaultValues.gdprAccept
        }}
        error={props.form.formState.errors.gdprAccept}
      />
      <Button
        color="primary"
        onClick={props.form.handleSubmit((form) => submitForm(form))}
        variant="contained"
        className={styles.submitButton}
      >
        Enviar candidatura
      </Button>
    </>
  );
};

CurriculumForm.defaultProps = {
  offerId: '',
  offerTitle: ''
};
