import { InitOptions } from 'i18next';
import { keyBy, mapValues } from 'lodash';
import moment from 'moment';
import { MGNL_API_I18N } from '../../constants';
import { getAPIBase, inDevelopment } from '../../helpers/AppHelpers';
import { customPathLocaleDetection } from './locale.detector';
import { DEFAULT_LANGUAGE, FormatType, Languages } from './locale.model';

const detectorOpts = {
  // order and from where user language should be detected
  order: [customPathLocaleDetection.name, 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'locale',
  lookupSessionStorage: 'locale',

  // cache user language on
  caches: ['localStorage'],
  excludeCacheFor: ['cimode']
};

const formatKeys = {
  'format.dateTime': '{{data, DD/MM/YYYY - HH:mm}}',
  'format.date': '{{data, DD/MM/YYYY}}',
  'format.stringDate': '{{data, dddd, DD [de] MMMM [de] YYYY}}'
};

const parseLanguages = (data) => {
  const dataMap = keyBy(JSON.parse(data), (obj) => obj.name);
  const keyMap = mapValues(dataMap, (obj) => obj.text);
  return { ...keyMap, formatKeys };
};

const backendOpts = {
  loadPath: `${getAPIBase()}${MGNL_API_I18N}?lang={{lng}}`,
  parse: parseLanguages
};

export const localeOpts: InitOptions = {
  backend: backendOpts,
  detection: detectorOpts,
  fallbackLng: DEFAULT_LANGUAGE,
  debug: inDevelopment(),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: (data, format) => {
      switch (data.format) {
        case FormatType.date:
        case FormatType.dateTime:
        case FormatType.stringDate:
          return moment(data.value).format(format);
        default:
          return data.value;
      }
    }
  },
  react: {
    useSuspense: true
  }
};

export const DateLocales = {
  [Languages.ES]: {
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  }
};
