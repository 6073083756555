import { Box, Button, Card, CardActions, CardContent, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MagnoliaEndpoints } from '../../constants';
import { getAPIPage } from '../../helpers/AppHelpers';
import { Project } from '../../models/Project';
import { StylesProps } from '../../models/StylesProps';
import { get } from '../../services/http.service';
import { useSwipperNavigationStyles } from '../../styles/common/swiper-navigation';
import { Filter } from '../filter/Filter';
import { Image } from '../image/Image';
import { ModalRef } from '../modal/modal';
import { filterProjects, getSlideNumber } from './ProjectCarousel.business';
import { projectCarouselFilters } from './ProjectCarousel.constants';
import { useProjectCarouselStyles } from './ProjectCarousel.styles';

interface Props extends StylesProps {
  showFilter?: boolean;
  showTitle?: boolean;
  currentProject?: string;
  labelSection?: string;
  headerClassName?: string;
  headerTitle?: string;
  headerSubtitle?: string;
}

export const ProjectCarousel: React.VFC<Props> = (props) => {
  const theme = useTheme();
  const modalRef = React.useRef<ModalRef>();
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const styles = useProjectCarouselStyles();
  const swipperStyles = useSwipperNavigationStyles(true);
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [filters, setFilters] = React.useState<Record<string, string[]>>();

  React.useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = async () => {
    try {
      const result = await get<Project[]>(getAPIPage(MagnoliaEndpoints.PROJECTS, true), { baseURL: '' });
      const filterProjects = result.data?.filter((data) => data.name !== props.currentProject) ?? [];
      setProjects(filterProjects);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeFilters = (newFilters: Record<string, string[]>) => setFilters(newFilters);
  const toggleModal = () => modalRef.current?.toggleModal();
  const filteredProjects = React.useMemo(() => filterProjects(projects, filters), [projects, filters]);
  const numberSlides = React.useMemo(() => getSlideNumber(isDesktop, isTablet), [isDesktop, isTablet]);
  
  return (
    <Box>
      <Filter modalRef={modalRef} columns={projectCarouselFilters} onChangeFilters={onChangeFilters} title="PROYECTOS" />
      <Container>
        <Box className={styles.headerContainer}>
          <Box className={props.headerClassName ?? styles.headerTextContainer}>
            <Typography variant="subtitle1">{props.headerTitle ?? 'Otros proyectos'}</Typography>
            <Typography variant="h5" className={styles.headerSubtitle}>
              {props.headerSubtitle ?? 'DESTACADOS'}
            </Typography>
          </Box>
          {props.showFilter && (
            <Button className={styles.filterButton} onClick={toggleModal}>
              Filtrar proyectos
            </Button>
          )}
        </Box>
        <Box className={styles.swiperContainer}>
          <div id="navigation-project-prev" className={swipperStyles.navigation}>
            &#9664;
          </div>
          <Swiper
            navigation={{
              prevEl: '#navigation-project-prev',
              nextEl: '#navigation-project-next',
              disabledClass: swipperStyles.disabledNavigation
            }}
            pagination={{
              clickable: true,
              el: '#pagination',
              bulletClass: swipperStyles.bullet,
              bulletActiveClass: swipperStyles.activeBullet
            }}
            slidesPerView={numberSlides}
            slidesPerColumnFill={'row'}
            slidesPerColumn={1}
            slidesPerGroup={numberSlides}
            role="grid"
            direction="horizontal"
            loopFillGroupWithBlank={true}
            className={styles.swiper}
          >
            {filteredProjects.map((project, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                {project && (
                  <Box className={styles.cardContainer}>
                    <Card variant="elevation" elevation={5} className={styles.card}>
                      <CardContent className={styles.cardContent}>
                        <Image image={project?.logo} className={styles.image} wrapperClassName={styles.wrapperImage} />
                        {props.showTitle && <Typography className={styles.title}>{project?.title}</Typography>}
                        <Typography className={styles.description}>{project?.description}</Typography>
                      </CardContent>
                      <CardActions classes={{ root: styles.cardActions }}>
                        <Button href={project['@path']} className={styles.linkButton}>
                          Ver este proyecto
                        </Button>
                      </CardActions>
                    </Card>
                    <Button href={project['@path']} className={styles.rightButton}>
                      +
                    </Button>
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <div id="navigation-project-next" className={swipperStyles.navigation}>
            &#9654;
          </div>
        </Box>
        <div className={styles.pagination} id="pagination" />
      </Container>
      {props.labelSection && (
        <Typography variant="h1" className={styles.sectionTitle}>
          {props.labelSection}
        </Typography>
      )}
    </Box>
  );
};

ProjectCarousel.defaultProps = {
  showFilter: true,
}
