import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';

export interface VideoDialogProps {
  open: boolean;
  urlVideo: MagnoliaImage;
  onClose: (value: string) => void;
}

const VideoDialog: React.VFC<VideoDialogProps> = ({ open, urlVideo, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog">
        <DialogContent>{urlVideo && <ReactPlayer width="100%" controls url={getMagnoliaImage(urlVideo)} playing={true} />}</DialogContent>
      </Dialog>
    </>
  );
};

export default VideoDialog;
