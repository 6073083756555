import { EditableArea } from '@magnolia/react-editor';
import { Box, Container, Divider } from '@material-ui/core';
import React from 'react';
import { StylesProps } from '../../models/StylesProps';
import { useActivitiesHeaderStyles } from './ActivitiesHeader.style';

export interface ActivitiesHeaderProps extends StylesProps {
  multiLinks?: any;
  content?: object;
  header?: object;
  background?: string;
  metadata: object;
}

export const ActivitiesHeader: React.VFC<ActivitiesHeaderProps> = (props) => {
  const parentTemplateId = props.metadata['mgnl:template'];
  const styles = useActivitiesHeaderStyles({ ...props });

  return (
    <div className={styles.container}>
      <Container>
        <Box>{props.header && <EditableArea content={props.header} parentTemplateId={parentTemplateId} />}</Box>
        <ul className={styles.listContainer}>
          {props.multiLinks &&
            props.multiLinks['@nodes'].map((node, index) => (
              <li className={styles.listItem} key={index}>
                <div className={styles.content}>
                  <span className={styles.text}>{props.multiLinks[node].linkText}</span>
                  <span className={styles.triangle}>&#9660;</span>
                </div>
                {index !== props.multiLinks['@nodes'].length - 1 && <Divider className={styles.divider} />}
              </li>
            ))}
        </ul>
        <Box>{props.content && <EditableArea content={props.content} parentTemplateId={parentTemplateId} />}</Box>
      </Container>
    </div>
  );
};

ActivitiesHeader.defaultProps = {
  containerCheckbox: 'true',
  marginBottom: '0',
  marginTop: '0',
  justifyContent: 'center',
  backgroundColor: 'blueInverseGradient'
};
