import { Container } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { MagnoliaImage } from '../../models/magnolia/MagnoliaImage';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { StylesProps } from '../../models/StylesProps';
import RichText from '../rich_text/RichText';
import { useImageDetailStyles } from './ImageDetail.style';

export interface ImageDetailProps extends StylesProps {
  defaultDescription: string;
  imageDetail: MagnoliaJcrMultiField;
}

interface ImageDetailModel {
  image: MagnoliaImage;
  description: string;
}

export const ImageDetail: React.FC<ImageDetailProps> = (props) => {
  const classes = useImageDetailStyles({ ...props });
  const { defaultDescription, imageDetail } = props;
  const [selectedImage, setSelectedImage] = useState<ImageDetailModel>();
  useEffect(() => {
    if (imageDetail && imageDetail['@nodes'].length) {
      const imageNode = imageDetail[imageDetail['@nodes'][0]] as ImageDetailModel;
      onSelectImage({
        image: imageNode.image,
        description: imageNode.description
      });
    }
  }, [defaultDescription, imageDetail]);

  const onSelectImage = (image: ImageDetailModel): void => {
    const imageMapper = {
      image: image.image,
      description: image.description ?? defaultDescription
    };
    setSelectedImage(imageMapper);
  };

  return (
    <div className={classes.mainContainer}>
      {selectedImage && (
        <Container className={classes.mainWrapper}>
          <div className={classes.descriptionContainer}>{selectedImage.description && <RichText text={selectedImage.description} />}</div>
          <div className={classes.imagesContainer}>
            <div
              className={classes.selectedImage}
              style={{ backgroundImage: `url(${getMagnoliaImage(selectedImage.image['@id'])})` }}
            ></div>
            <div className={classes.imageList}>
              {imageDetail['@nodes'].map((node) => {
                const imageDetailModel: ImageDetailModel = imageDetail[node];
                return (
                  <div
                    key={node}
                    className={clsx(classes.imageItem, { active: imageDetailModel.image['@id'] === selectedImage.image['@id'] })}
                    style={{ backgroundImage: `url(${getMagnoliaImage(imageDetailModel.image['@id'])})` }}
                    onClick={() => onSelectImage(imageDetailModel)}
                  ></div>
                );
              })}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

ImageDetail.defaultProps = {
  marginBottom: '0',
  marginTop: '0',
  tabletMarginBottom: '0',
  tabletMarginTop: '0',
  mobileMarginBottom: '0',
  mobileMarginTop: '0'
};
