import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { marginBottomAndTop } from '../../styles/globals';

const usePartnerBannerStyles = makeStyles((theme) => ({
  container: ({ marginTop, marginBottom, tabletMarginBottom, tabletMarginTop, mobileMarginBottom, mobileMarginTop }: StylesProps) => ({
    ...marginBottomAndTop(marginTop, marginBottom),
    [theme.breakpoints.down('xs')]: {
      ...marginBottomAndTop(mobileMarginTop, mobileMarginBottom)
    },
    [theme.breakpoints.only('md')]: {
      ...marginBottomAndTop(tabletMarginTop, tabletMarginBottom)
    }
  }),
  root: {
    padding: '100px 0 50px 0',
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 0
      }
    },
    '& .imageColumn': {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '40px 25px 0 25px'
      },
      '& img': {
        width: '100%'
      }
    },
    '& .partnersColumn': {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '& .richText': {
        width: '55%',
        margin: '0 130px 0 auto',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: 0,
          padding: '10px 15px',
          textAlign: 'center'
        }
      }
    }
  }
}));

export default usePartnerBannerStyles;
