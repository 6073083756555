import { makeStyles } from '@material-ui/core/styles';
import { StylesProps } from '../../models/StylesProps';
import { RESET_CONTAINER_STYLE } from '../../styles/globals';

export const useButtonComponentStyles = makeStyles((theme) => ({
  btn: ({ marginTop }: StylesProps) => ({
    position: 'relative',
    marginRight: 20,
    marginTop,
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '10px',
      marginLeft: 30,
      marginRight: 30
    },
    '&:last-child': {
      marginRight: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: 30
      }
    }
  }),
  colorWhite: {
    borderColor: '#fff',
    color: '#fff'
  },
  componentPersonalClases: {},
  removeContainer: {
    ...RESET_CONTAINER_STYLE
  }
}));
