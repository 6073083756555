import React from 'react';
import { getMagnoliaImage } from '../../../helpers/AppHelpers';
import { MagnoliaJcrMultiField } from '../../../models/magnolia/MagnoliaJcrMultiField';
import RichText from '../../rich_text/RichText';

interface GeneratePartnerBannerProps {
  slides: MagnoliaJcrMultiField;
  slideNodes: string[];
}

const GeneratePartnerBanner = (props: GeneratePartnerBannerProps) => {
  return props.slideNodes.map((name) => {
    return (
      <div key={name}>
        <RichText marginBottom={'30'} text={props.slides[name].text ?? ''} />
        {props.slides[name].image && (
          <div>
            <img src={getMagnoliaImage(props.slides[name].image['@id'] ?? props.slides[name].image)} />
          </div>
        )}
      </div>
    );
  });
};

export default GeneratePartnerBanner;
