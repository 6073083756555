import { Divider } from '@material-ui/core';
import React from 'react';
import { MagnoliaImage } from '../../../models/magnolia/MagnoliaImage';
import { LazyImage } from '../../image/LazyImage';
import RichText from '../../rich_text/RichText';
import { useImageDescriptionItemStyles } from './ImageDescriptionItem.styles';

interface Props {
  image: MagnoliaImage;
  text: string;
  divider: boolean;
}

export const ImageDescriptionItem: React.VFC<Props> = (props) => {
  const styles = useImageDescriptionItemStyles();
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <LazyImage image={props.image} className={styles.image} />
        <RichText text={props.text} />
      </div>
      { props.divider && <Divider />}
    </div>
  );
};
