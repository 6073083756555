import { Box, Button, Container, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import React from 'react';
import { MagnoliaJcrMultiField } from '../../models/magnolia/MagnoliaJcrMultiField';
import { MagnoliaMetadata } from '../../models/magnolia/MagnoliaMetadata';
import { StylesProps } from '../../models/StylesProps';
import { ButtonList, ButtonListProps } from '../button_list/ButtonList';
import { LazyImage } from '../image/LazyImage';
import { useSelectableContentStyles } from './SelectableContent.styles';

interface Props extends StylesProps {
  selectables: MagnoliaJcrMultiField;
  description: string;
  metadata: MagnoliaMetadata;
}

export const SelectableContent: React.VFC<Props> = (props) => {
  const [selectedSubject, setState] = React.useState(props.selectables ? props.selectables['@nodes'][0] : '');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const styles = useSelectableContentStyles(props);

  const selectableButtonListProps: ButtonListProps = {
    button: props.selectables[selectedSubject]?.buttons,
    buttonPosition: 'left',
    marginLeft: '20',
    tabletMarginLeft: '20'
  };

  const onClick = (value: string) => () => setState(value);

  return (
    <div className={styles.background}>
      <Container className={styles.removePadding}>
        <div className={styles.container}>
          <div className={styles.buttonContainer}>
            {props.selectables['@nodes'].map((key, index) => (
              <Button
                variant={key === selectedSubject ? 'contained' : 'outlined'}
                color={key === selectedSubject ? 'primary' : 'default'}
                className={key === selectedSubject ? styles.selectedButton : styles.button}
                onClick={onClick(key)}
                startIcon={<ArrowForward />}
                key={index}
              >
                {props.selectables[key].name}
              </Button>
            ))}
          </div>
          <div className={styles.selectableContainer}>
            {selectedSubject && (
              <Box className={styles.selectableInfoContainer}>
                <LazyImage className={styles.selectableImage} image={props.selectables[selectedSubject].image} />

                <Box className={styles.selectableBody}>
                  <Box
                    className={styles.subjectDescription}
                    dangerouslySetInnerHTML={{ __html: props.selectables[selectedSubject].description ?? '' }}
                  />

                  {!!props.selectables[selectedSubject]?.buttons && <ButtonList {...selectableButtonListProps} />}
                </Box>

                {isDesktop && <span className={styles.selectableOverflowBackground} />}
              </Box>
            )}

            <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>
        </div>
      </Container>
    </div>
  );
};
