import { makeStyles } from '@material-ui/core/styles';

export const useVideoCardStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    background: 'white',
    padding: 30,
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
    marginRight: 30,
    [theme.breakpoints.up('md')]: {
      maxWidth: '55%',
    }
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-70px)',
    },
  }
}));
